import {
  faCalendar,
  faComments,
  faCreditCard,
  faFileLines,
  faGear,
  faList,
  faTag,
  faUser,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

export const NavbarMenuOptions = () => {
  return [
    { icon: faUser, destination: "/account/dashboard", text: "Dashboard" },
    {
      icon: faComments,
      destination: "/account/messages",
      text: t("account_sidebar_messages"),
    },
    {
      icon: faList,
      destination: "/account/my-rentals",
      text: t("account_sidebar_rentals"),
    },
    {
      icon: faTag,
      destination: "/account/my-items",
      text: t("account_sidebar_listings"),
    },
    {
      icon: faCalendar,
      destination: "/account/my-bookings",
      text: t("account_sidebar_bookings"),
      indent: true,
    },
   
    { icon: faUserCog, destination: "/account/profile", text: "Profile" },
    {
      icon: faCreditCard,
      destination: "/account/verification",
      text: t("account_sidebar_personal_details"),
      indent: true,
    },
    {
      icon: faGear,
      destination: "/account/settings",
      text: t("account_sidebar_settings"),
      indent: true,
    },
  ];
};
