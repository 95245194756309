import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { Button, Card, Col, Container, Row } from 'reactstrap';
import { differenceInCalendarDays, format, isAfter, isBefore } from 'date-fns';
import { faChevronLeft, faInfoCircle, faCheckCircle, faFileCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { HandoverModal } from './HandoverModal';
import { BookingReview } from './BookingReview';
import { LoadingFullScreen } from '../../common/LoadingFullScreen';
import AccountPagesSidebar from '../AccountPagesSidebar';
import ImageCarouselModal from '../../common/ImageCarouselModal';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BookingClient,
  BookingDetailedDto,
  BookingStatus,
  FileClient,
  HandOverCreateDto,
  IConfig,
  IImageDetailedDto,
  IImageSimpleDto,
  InsurancePlanDto,
  ItemClient,
  ItemPublicDto,
  PaymentStatus,
  PricePeriod,
  StripeCheckoutRedirects,
} from '../../../api/rentMyApi';
import { useEnv } from '../../../context/env.context';
import LocationCard from '../../item-detailed/LocationCard';
import { getStandardPricePeriod } from '../../item/ItemListingConstantsAndDefaults';
import { HourlyClockArray } from '../../../constants/HourlyClockOptions';
import { gotoMessagesFromBookingDetailsPage, messageUrlConst } from '../../common/MessageUrlFormatting';
import { itemRefundedAnalytics, sendRefundEvent } from '../../../api/analytics';
import { useUserContext } from '../../../context/UserContext';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface ImageEnhanced extends IImageSimpleDto {
  isPickUp: boolean;
}

interface ImageEnhancedDetailed extends IImageDetailedDto {
  isPickUp: boolean;
}

const setPageTitle = (isSharerView: boolean, bookingPeriodHasStarted: boolean, bookingPeriodHasEnded: boolean, booking: BookingDetailedDto) => {
  const bookingStatus = booking.bookingStatus;

  const bookingIsRequested = bookingStatus === BookingStatus['Request'];
  const bookingIsOngoing = bookingStatus === BookingStatus['Ongoing'];
  const bookingIsCompleted = bookingStatus === BookingStatus['Completed'];
  const bookingIsAccepted = bookingStatus === BookingStatus['BookingAccepted'];
  const bookingWasCancelled = bookingStatus === BookingStatus['Cancelled'];

  const bookingCanBeAccepted = bookingIsRequested && isSharerView;

  const bookingNeedsPayment =
    booking.paymentStatus === PaymentStatus.Failed || booking.paymentStatus === PaymentStatus.Unauthorised;

  if (bookingWasCancelled) return 'This booking was cancelled.';

  if (bookingIsRequested) {
    if (isSharerView) {
      return 'Please accept or reject this booking.';
    } else {
      return 'The Sharer must accept or reject this booking.';
    }
  }

  if (bookingNeedsPayment) {
    if (isSharerView) {
      return 'Booking accepted. Awaiting payment from Renter';
    } else {
      return 'Please complete payment to continue with this booking.';
    }
  }

  if (bookingIsCompleted) return 'This booking has been completed.';

  if (bookingIsAccepted) {
    if (!bookingPeriodHasStarted) {
      const daysBeforeStart = differenceInCalendarDays(booking.startDate!, new Date());
      if (daysBeforeStart)
        return `This booking has been accepted and starts in ${daysBeforeStart} day${daysBeforeStart > 1 ? 's.' : '.'}`;
    } else {
      if (isSharerView) {
        return 'The booking period has started. Please remind the renter to complete pick-up/check-in.';
      } else {
        return 'The booking period has started. Please complete pick-up/check-in.';
      }
    }
  }
  if (bookingIsOngoing) {
    const daysBeforeEnd = differenceInCalendarDays(booking.endDate!, new Date());
    if (bookingPeriodHasEnded) {
      if (daysBeforeEnd === 0) {
        if (isSharerView) {
          return 'The booking period ends today. You need to complete the return/checkout.';
        } else {
          return 'Your booking period ends today. Please remind the sharer to complete the return/checkout.';
        }
      } else {
        if (isSharerView) {
          return 'The booking has ended. You need to complete the return/checkout.';
        } else {
          return 'Your booking period has ended. Please remind the sharer to complete the return/checkout.';
        }
      }
    } else {
      if (daysBeforeEnd === 0) {
        return 'Ends today';
      } else {
        return `This booking is ongoing and ends in ${daysBeforeEnd} day${daysBeforeEnd > 1 ? 's.' : '.'}`;
      }
    }
  }
};

interface BookingDetailsProps {
  bookingId: string;
  onClose: (value?: BookingDetailedDto) => void;
  bookingClient: BookingClient;
  isClientLoaded: boolean;
  isSharerView: boolean;
  initialView?: string;
  handoverModalOpen?: boolean;
}

export function BookingDetails({
                                 bookingId,
                                 onClose,
                                 bookingClient,
                                 isClientLoaded,
                                 isSharerView,
                                 initialView = 'booking',
                                 handoverModalOpen = false,
                               }: BookingDetailsProps) {
  const [view, setView] = useState<string>(initialView);
  const [showHandoverModal, setShowHandoverModal] = useState(handoverModalOpen);
  const [images, setImages] = useState<ImageEnhancedDetailed[]>([]);
  const [imagesZoomed, setImagesZoomed] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [booking, setBooking] = useState<BookingDetailedDto>(new BookingDetailedDto());
  const [checkInTime, setCheckInTime] = useState<string>('');
  const [checkOutTime, setCheckOutTime] = useState<string>('');
  const [sharerId, setSharerId] = useState<string>('');

  const [insurancePremiumTax, setInsurancePremiumTax] = useState<number>(0);
  const [totalInsurancePremium, setTotalInsurancePremium] = useState<number>(0);
  const enableInsurance = process.env.REACT_APP_SHOW_INSURANCE && /true/.test(process.env.REACT_APP_SHOW_INSURANCE);
  const { loginWithRedirect, isAuthenticated, logout, loginWithPopup, isLoading } =
      useAuth0();
  const startDate = booking?.startDate ?? 0;
  const endDate = booking?.endDate ?? 0;

  const daysBetween = Math.abs(differenceInCalendarDays(startDate, endDate));
  const dailyPrice = booking.paymentAmount / daysBetween;
  const insuranceName = booking.insuranceBreakdown?.insurancePlan.name;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const showConfirmationToaster = urlParams.get('showConfirmationToaster');
    if (!isSharerView && showConfirmationToaster) {
      toast.success('Your booking request has been successfully submitted');
    }
  }, []);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState('A claim can only be submitted within 4 weeks of the rental period.');

  const bookingNeedsPayment =
    booking.paymentStatus === PaymentStatus.Failed || booking.paymentStatus === PaymentStatus.Unauthorised;

  const { bookingStatus } = booking;
  const { apiServerUrl, supportUrl, allowEarlyPickup } = useEnv();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const navigate = useNavigate();

  const bookingPoll = useRef(null);

  const stopPolling = () => {
    if(bookingPoll.current) {
      clearInterval(bookingPoll.current);
    }
  };

    const fetchBookingDetails = async () => {
        const token = await getAccessTokenSilently();
        const bookingClient = new BookingClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
        let pollBooking;
        try {
            pollBooking = await bookingClient.detailed(bookingId);
            setBooking(pollBooking);
            processImages(pollBooking); // Process images
            setCheckInOutTime(pollBooking);
        } catch (error) {
            toast.error('There was an error fetching booking details');
        }
        if(pollBooking.insuranceQuote && !pollBooking.insurancePolicyPdfUrl) {
            // @ts-expect-error - timeout
            bookingPoll.current = setTimeout(fetchBookingDetails, 2000);
        }
    };


  useEffect(() => {
    if(!bookingId) return () => {}
    fetchBookingDetails();

    return () => stopPolling();
  }, [bookingId])

  useEffect(() => {
    console.log('booking.insuranceBreakdown');
    console.log(booking.insuranceBreakdown);
    if (enableInsurance && booking.insuranceBreakdown) {
      console.log('booking.insuranceBreakdown');
      console.log(booking.insuranceBreakdown);

      const insurance = booking.insuranceBreakdown;

      const { iptAmount, netPremium } = insurance;

      setInsurancePremiumTax(iptAmount);
      setTotalInsurancePremium(netPremium + iptAmount);
    }
  }, [booking]);

  const payForItem = () => {
    const rentalLocationUrl = '/account/my-rentals';
    const successResponse = window.location.origin + rentalLocationUrl + '?id=' + bookingId;
    const cancelResponse = window.location.href + '&cancel-id=' + bookingId;
    const stripeCheckoutRedirects = new StripeCheckoutRedirects({
      cancel: cancelResponse,
      success: successResponse,
    });
    bookingClient
      .checkout(bookingId, undefined, stripeCheckoutRedirects)
      .then((response) => {
        window.location.href = response;
        setIsProcessing(false);
      })
      .catch(async () => {
        setIsProcessing(false);
        toast.error('There was an error making this booking, please contact support');
      });
  };

  const processImages = async (booking: BookingDetailedDto) => {
    const { pickUpImages = [], dropOffImages = [] } = booking;

    const combinedImages: ImageEnhanced[] = [];
    pickUpImages.forEach((image) => {
      combinedImages.push({
        ...image,
        isPickUp: true,
      });
    });

    dropOffImages.forEach((image) => {
      combinedImages.push({
        ...image,
        isPickUp: false,
      });
    });

    // remove this when images returned fully
    const token = await getAccessTokenSilently();
    const fileClient = new FileClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);

    const promises = combinedImages.map((image) =>
      fileClient.detailed16(image.id!).then((res): ImageEnhancedDetailed => ({
        ...image,
        ...res,
        // Ensure all properties required by ImageEnhancedDetailed are correctly merged here
      })),
    );

    Promise.all(promises).then((images: ImageEnhancedDetailed[]) => {
      setImages(images); // Now TypeScript knows `images` is of type ImageEnhancedDetailed[]
    }).catch(error => {
      // Handle or log error
      console.error('Error processing images', error);
    });

  };

  async function setCheckInOutTime(data: BookingDetailedDto) {
    const token = await getAccessTokenSilently();
    const itemClient = new ItemClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
    itemClient
      .detailed24(data.item.id)
      .then((response: ItemPublicDto) => {
        if (!response.priceInfo.periods) {
          // console.log("There are no price periods, therefore we cannot find out the check-in / return time.");
          return;
        }

        const standardPricePeriod: PricePeriod | undefined = getStandardPricePeriod(response.priceInfo.periods);

        const checkInIndex = standardPricePeriod?.price.hourlyStart ?? 0; // Default to 0 or another safe index if undefined
        const checkOutIndex = standardPricePeriod?.price.hourlyEnd ?? 0; // Default to 0 or another safe index if undefined

        // Using type assertion to assure TypeScript the index is a number.
        setCheckInTime(HourlyClockArray[checkInIndex as number]);
        setCheckOutTime(HourlyClockArray[checkOutIndex as number]);

        setSharerId(response.user.id);
        // DEVNOTE - We are currently using hourlyStart to store the pickup time (as its missing on booking GET). This is incorrect however, hourly start is for hourly pricing. We need 2 new values on booking GET to retreive pickup/return time correctly.

        //----------------------
        // OLD CODE
        // // Frontend backlog: #827
        // setCheckInTime(
        //   HourlyClockArray[
        //     standardPricePeriod?.price.hourlyStart !== null && standardPricePeriod?.price.hourlyStart !== undefined
        //       ? standardPricePeriod?.price.hourlyStart
        //       : ''
        //     ],
        // );
        // setCheckOutTime(
        //   HourlyClockArray[
        //     standardPricePeriod?.price.hourlyEnd !== null && standardPricePeriod?.price.hourlyEnd !== undefined
        //       ? standardPricePeriod?.price.hourlyEnd
        //       : ''
        //     ],
        // );
        setSharerId(response.user.id);
      })
      .catch((error: any) => {
        toast.error(t('account_bookings_err_check_in_out_times'));
        // console.error("Item details retrieving error", error);
      });
  }

  const HandoverConfirmation = () => {
    if (bookingStatus === 'Ongoing' || bookingIsCompleted)
      return (
        <Card className="custom-card">
          <h1>{t('account_bookings_handover_confirmation')}</h1>
          {images.length > 0 && (
            <div className="account-booking-list">
              {images
                .sort((a, b) => (isBefore(a.creation!, b.creation!) ? -1 : 1))
                .map((image, i) => (
                  <div className="booking-item uploaded-row" key={i}>
                    <div className="uploaded-row-image-div">
                      <img
                        src={`${apiServerUrl}/api/v1/${image.compressedPath}`}
                        alt="Uploaded"
                        className="uploaded-row-image"
                      />
                    </div>
                    <div>
                      <p className="uploaded-row-title">
                        {t('uploaded_by')} {image.user.name}
                      </p>
                      <span className="uploaded-row-subtitle">
                        {format(new Date(image.creation!), 'd MMM, yyyy - h:mma')}
                      </span>
                    </div>
                  </div>
                ))}
              <div className="handover-buttons">
                <Button
                  type="button"
                  outline
                  onClick={() => {
                    const imageUrls = images.map((image) => image!.path! ?? "");
                    setImagesZoomed(imageUrls);
                  }}
                  color="primary"
                  className="min-width"
                >
                  {t('btn_view')}
                </Button>
              </div>
            </div>
          )}
        </Card>
      );
    else return null;
  };

  const onStatusChange = async (status: BookingStatus, imageId?: string): Promise<BookingDetailedDto> => {
    if (imageId) {
      await bookingClient.addImages(bookingId, [imageId], status === 'Ongoing');
    }

    const handOverCreateDto = new HandOverCreateDto({
      location: {
        name: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        country: '',
        county: '',
        postcode: '',
        latitude: 0,
        longitude: 0,
      },
      time: new Date(),
    });

    return await bookingClient.updateStatus(
      bookingId,
      BookingStatus[status],
      undefined,
      undefined,
      handOverCreateDto,
      undefined,
    );
  };

  const onCancelOrAcceptBooking = async (status: BookingStatus) => {
    setIsProcessing(true);
    try {
      const updatedBooking = await onStatusChange(status);
      onClose(updatedBooking);
      setIsProcessing(false);
    } catch (error) {
      toast.error('Something went wrong. Try again or contact support.');
      setIsProcessing(false);
    }
  };

  const cancelBooking = async () => {
    setIsProcessing(true);
    try {
      const updatedBooking = await bookingClient.cancel(bookingId);
      itemRefundedAnalytics({});
      sendRefundEvent('GBP', 40);
      setIsProcessing(false);
      toast.success('This booking has been cancelled');
      window.location.reload();
    } catch (error) {
      toast.error('Something went wrong. Try again or contact support.');
      setIsProcessing(false);
    }
  };

  const handoverButtonText =
    bookingStatus === BookingStatus.BookingAccepted ? t('btn_confirm_pick_up') : t('btn_complete_drop_off');

  const startOfBooking = new Date(booking.startDate!);
  const endOfBooking = new Date(booking.endDate!);
  const bookingPeriodHasStarted = isBefore(startOfBooking, new Date());
  const bookingPeriodHasEnded = isAfter(new Date(), endOfBooking);
  const isWithinClaimPeriod = differenceInCalendarDays(new Date(), endOfBooking) <= 28;

  const bookingIsRequested = bookingStatus === BookingStatus.Request;
  const bookingIsOngoing = bookingStatus === BookingStatus.Ongoing;
  const bookingIsCompleted = bookingStatus === BookingStatus.Completed;
  const bookingIsAccepted = bookingStatus === BookingStatus.BookingAccepted;

  const bookingCanBeAccepted = bookingIsRequested && isSharerView;

  const isActive =
    ((bookingIsAccepted) && bookingPeriodHasStarted) || bookingIsOngoing;

  const canMakeAClaim =
    isSharerView &&
    (bookingIsCompleted || bookingPeriodHasEnded) &&
    !booking.hasClaim &&
    booking.insuranceBreakdown !== undefined;

  const title = setPageTitle(isSharerView, bookingPeriodHasStarted, bookingPeriodHasEnded, booking);


  const downloadInsuranceDoc = () => {
    if(!booking.insurancePolicyPdfUrl) return;
    window.open(booking.insurancePolicyPdfUrl, '_blank');
  }

  const handleMessageSharerClick = () => {
    navigate(
      `${messageUrlConst}/${gotoMessagesFromBookingDetailsPage(
        booking.item.name!,
        booking.id,
        booking.startDate!,
        booking.endDate!,
      )}/${isSharerView ? booking.renter.id : booking.sharer.id}`,
    );
  };

  return (
    <Container className="mt-4 account-settings-container">
      <HandoverModal
        isOpen={showHandoverModal}
        onClose={() => setShowHandoverModal(false)}
        isSharerView={bookingStatus === BookingStatus.Ongoing}
        bookingId={bookingId}
        hasInsurance={!!booking.insuranceBreakdown}
        endDate={booking.endDate}
      />
      <ImageCarouselModal
        imageSrcs={imagesZoomed}
        toggleModal={() => setImagesZoomed([])}
        modalState={imagesZoomed.length > 0}
      />
      {isProcessing && <LoadingFullScreen />}
      <Row>
        <Col md={4} lg={4} xl={3} className="hide-on-mobile hide-on-tablet">
          <AccountPagesSidebar />
        </Col>
        <Col md={8} lg={8} xl={9}>
          <div className="booking-info_header">
            <button
              className="booking-back-button"
              onClick={() => navigate(isSharerView ? '/account/my-bookings' : '/account/my-rentals', { replace: true })}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <h1 className="no-bottom-margin">{booking?.item?.name}</h1>
          </div>
          {view === 'booking' && booking && booking.id && (
            <>
              <br />
              {title && (
                <Card className="custom-card">
                  <h1 className="account-details-internal-card-padding">{title}</h1>
                  <div className="handover-buttons">
                    {!isSharerView &&
                      !BookingStatus['Cancelled'] &&
                      (booking.paymentStatus === PaymentStatus.Failed ||
                        booking.paymentStatus === PaymentStatus.Unauthorised) && (
                        <Button
                          className="min-width"
                          outline
                          color="primary"
                          onClick={() => payForItem()}
                          type="button"
                        >
                          Pay
                        </Button>
                      )}
                    {bookingCanBeAccepted && (
                      <Button color="primary" type="button"
                              onClick={() => onCancelOrAcceptBooking(BookingStatus.BookingAccepted)}>
                        {t('account_bookings_accept_booking')}
                      </Button>
                    )}
                    {!(
                        booking.paymentStatus === PaymentStatus.Failed ||
                        booking.paymentStatus === PaymentStatus.Unauthorised
                      ) &&
                      bookingPeriodHasStarted &&
                      !isSharerView &&
                      bookingStatus === 'BookingAccepted' && (
                        <Button
                          disabled={!isActive}
                          color="primary"
                          type="button"
                          onClick={() => setShowHandoverModal(true)}
                          key={bookingStatus}
                        >
                          {handoverButtonText}
                        </Button>
                      )}
                    {!(
                        booking.paymentStatus === PaymentStatus.Failed ||
                        booking.paymentStatus === PaymentStatus.Unauthorised
                      ) &&
                      isSharerView &&
                      bookingStatus === 'Ongoing' && (
                        <Button
                          disabled={!isActive}
                          color="primary"
                          type="button"
                          onClick={() => setShowHandoverModal(true)}
                          key={bookingStatus}
                        >
                          {handoverButtonText}
                        </Button>
                      )}
                    {!isSharerView && bookingIsCompleted && !booking.isReviewed && (
                      <Button color="primary" type="button" onClick={() => setView('review')}>
                        {t('review_booking')}
                      </Button>
                    )}

                    <Button
                      outline
                      color="primary"
                      onClick={() => window.open(' https://zen.rentmy.com/hc/en-gb/requests/new', '_blank')}
                      type="button"
                    >
                      {t('account_bookings_report_issue')}
                    </Button>

                    {(bookingIsRequested || bookingIsAccepted) && (
                      <Button outline color="primary" type="button" onClick={() => cancelBooking()}>
                        {t('account_bookings_cancel_booking')}
                      </Button>
                    )}

                    <Button
                      type="button"
                      outline
                      onClick={handleMessageSharerClick}
                      color="primary"
                      className="min-width"
                    >
                      {isSharerView ? 'Message Renter' : 'Message Sharer'}
                    </Button>
                   

                    {canMakeAClaim ? (
                      <span
                        onMouseEnter={() => {
                          if (!isWithinClaimPeriod) {
                            setIsTooltipVisible(true);
                          }
                        }}
                        onMouseLeave={() => setIsTooltipVisible(false)}
                        onTouchStart={() => {
                          if (!isWithinClaimPeriod) {
                            setIsTooltipVisible(true);
                            setTimeout(() => setIsTooltipVisible(false), 5000);
                          }
                        }}
                        style={{ position: 'relative', display: 'inline-block' }}
                      >
                        <Button
                          type="button"
                          outline
                          onClick={() => {
                            if (isWithinClaimPeriod) {
                              navigate('/insurance-claim/' + bookingId);
                            }
                          }}
                          color="primary"
                          className="min-width"
                          disabled={!isWithinClaimPeriod}
                        >
                          Make a claim
                          {!isWithinClaimPeriod && (
                            <FontAwesomeIcon icon={faInfoCircle} color="grey" className="ml-2" />
                          )}
                        </Button>
                        {!isWithinClaimPeriod && (
                          <span className={`claim-tooltip ${isTooltipVisible ? 'visible' : 'hidden'}`}>
                            {tooltipText}
                          </span>
                        )}
                      </span>
                    ) : null}
                  </div>
                </Card>
              )}

                {
                    booking.insuranceQuote &&  (
                        <div className="checkout-insurance-banner">
                            <div className="checkout-insurance-banner-info">
                                <div className="checkout-insurance-banner-logo">
                                    <span>Provided by</span>
                                    <div className="protectmy-logo"></div>
                                </div>
                                <h3>Your Rental is Insured</h3>

                                <p className="sub-header">Your rental is protected with ProtectMy insurance</p>

                                <div className="divide"></div>

                                {booking.insurancePolicyPdfUrl ? (
                                    <div className="insurance-documents-container mt-1">
                                        <Button
                                            type="button"
                                            color="primary"
                                            onClick={downloadInsuranceDoc}
                                            className="min-width"
                                        >
                                            <FontAwesomeIcon icon={faFileCircleCheck} className="mr-2" />
                                            Download Insurance Documents
                                        </Button>
                                    </div>
                                ) : (
                                    <p className="opacity-7 mt-2 mb-2">
                                        <FontAwesomeIcon icon={faSpinner} spin/> Fetching your documents..
                                    </p>
                                )}

                                <p className="small-text mt-3">
                                    ProtectMy Ltd, Firm Reference No. 1018860, is an Appointed Representative of Innovative Risk Labs Ltd Authorised and Regulated by the Financial Conduct Authority Firm Reference No. 609155
                                </p>
                            </div>
                        </div>
                    )
                }

              {!bookingNeedsPayment && booking.active && (
                <LocationCard
                  latitude={
                    booking.item.location && booking.item.location.latitude
                      ? booking.item.location.latitude
                      : 0
                  }
                  longitude={
                    booking.item.location && booking.item.location.longitude
                      ? booking.item.location.longitude
                      : 0
                  }
                  city={
                    booking.item.location && booking.item.location.city
                      ? booking.item.location.city
                      : ''
                  }
                  country={
                    booking.item.location && booking.item.location.country
                      ? booking.item.location.country
                      : ''
                  }
                  wantMarker={true}
                  accurateLocation={
                    booking.item.location && booking.item.location.name
                      ? booking.item.location.name
                      : ''
                  }
                />
              )}
              <HandoverConfirmation />

              <Card className="custom-card">
                <h1 className={'account-details-internal-card-padding'}>{t('account_bookings_pick_up_details')}</h1>
                <Row className="ten-bottom-margin account-details-internal-card-padding">
                  <Col xs={6} className={'padding-right-4'}>
                    <p className="small-label">{t('account_bookings_rental_start_date')}</p>
                    <p className="booking-details-custom-select">{format(startOfBooking, 'dd MMMM yyyy')}</p>
                  </Col>
                  <Col xs={6} className={'padding-left-4'}>
                    <p className="small-label">{t('account_bookings_rental_end_date')}</p>
                    <p className="booking-details-custom-select">{format(endOfBooking, 'dd MMMM yyyy')}</p>
                  </Col>
                </Row>
                {checkInTime && checkOutTime && (
                  <Row className="account-details-internal-card-padding">
                    <Col xs={6} className={'padding-right-4'}>
                      <p className="small-label">{t('account_bookings_pick_up_time')}</p>
                      <p className="booking-details-custom-select">{checkInTime}</p>
                    </Col>
                    <Col xs={6} className={'padding-left-4'}>
                      <p className="small-label">{t('account_bookings_drop_off_time')}</p>
                      <p className="booking-details-custom-select">{checkOutTime}</p>
                    </Col>
                  </Row>
                )}
                <span className="custom-card-span account-details-internal-card-padding">
                  {t('account_bookings_pick_up_note')}
                </span>
              </Card>
              <Card className="custom-card">
                <h1 className="account-details-internal-card-padding">{t('account_bookings_booking_information')}</h1>
                <div className="booking-details-row account-details-internal-card-padding">
                  <p className="booking-details-label">{t('account_bookings_reference_number')}</p>
                  <span>{booking.id.split('-')[0].toUpperCase()}</span>
                </div>
                <div className="booking-details-row account-details-internal-card-padding">
                  <p className="booking-details-label">{t('account_bookings_cancellation_policy')}</p>
                  <span>{booking.item.cancellationPolicy}</span>
                </div>
                <div className="booking-details-row account-details-internal-card-padding">
                  <p className="booking-details-label">{t('account_bookings_booking_status')}</p>
                  <span>{bookingStatus === 'BookingAccepted' ? 'Accepted' : bookingStatus}</span>
                </div>
                {/* Price breakdown */}
                <div className="booking-details-row account-details-internal-card-padding">
                  <h2>{t('item_detailed_confirmation_price_breakdown')}</h2>
                  <div
                    style={{
                      border: '1px solid lightgrey',
                      borderRadius: '.5rem',
                      padding: '10px',
                    }}
                  >
                    {/* Price without fee */}
                    <div className="flexy item-detailed-price-breadown">
                      <p
                        id="date-range-price"
                        className="flex-vertically-center"
                      >
                        &#163;{dailyPrice.toFixed(2)}
                        {t("item_detailed_per_day_alt")} x {daysBetween}{" "}
                        {daysBetween > 1 ? t("days") : t("day")}
                      </p>
                      <p className="flex-right">&#163;{booking.paymentAmount.toFixed(2)}</p>
                    </div>
                    {/* RentMy fee */}
                    <div className="flexy item-detailed-price-breadown">
                      <p id="date-range-price" className="flex-vertically-center">
                        {t('rent_my_fee')}
                      </p>
                      <p className="flex-right">&#163;{booking.serviceFeeAmount.toFixed(2)}</p>
                    </div>
                    {/* Insurance */}
                    {!!booking.insuranceQuote && (
                      <>
                        <div className="flexy item-detailed-price-breadown">
                          <p id="date-range-price" className="flex-vertically-center">
                            {booking.insuranceQuote.insurerName} Insurance
                          </p>
                          <p className="flex-right">
                            &#163;
                            {(booking.insuranceQuote.total).toFixed(2)}
                          </p>
                        </div>
                        <p className="flex-left small" style={{ marginTop: '-10px' }}>
                          {'Includes Insurance Premium Tax of £' + (booking.insuranceQuote.tax).toFixed(2)}
                        </p>
                      </>
                    )}
                    {/* Discount */}
                    {booking.discount && (
                      <div
                        className="text-red"
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}
                      >
                        <div className="mb-0">
                          <p id="date-range-price" className="flex-vertically-center mb-0">
                            {t('discount')}
                          </p>
                          {isSharerView ? <p className="small mb-0">{t('deducted_from_fee')}</p> : ''}
                        </div>
                        <p className="flex-right mb-0">-&#163;{booking.discount.toFixed(2)}</p>
                      </div>
                    )}
                    <hr className="small-hr" />
                    <div className="flexy">
                      <p className="flex-vertically-center font-weight-600">
                        {isSharerView ? t('item_detailed_total_for_renter') : t('item_detailed_total')}
                      </p>
                      <p className="flex-right font-weight-600">&#163;{booking.totalAmount.toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              </Card>
            </>
          )}
          {view === 'review' && <BookingReview setIsProcessing={setIsProcessing} booking={booking} />}
        </Col>
      </Row>
    </Container>
  );
}
