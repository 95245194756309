// rentmy.frontend/src/components/Header/header.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import WhatWhereSearch from '../search/WhatWhereSearch';
import { useNavigate } from 'react-router-dom';
import LogoStripWhite from './LogoStripWhite'; // Import LogoStrip

export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [currentItem, setCurrentItem] = useState('');
  const [itemIndex, setItemIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const items = [
   "Bike", "Tent", "Drill", "Camera", "Kayak", "Ladder", "Guitar",
"DJ Kit", "3D Printer", "Drone", "Anything!", "Scooter", "Canoe",
"Trailer", "Campervan", "Surfboard", "Anything!", "Barbecue",
"Kitchen Aid", "Travel Cot", "Bicycle", "Projector", "Snowboard",
"Anything!", "Lawnmower", "Trampoline", "Microwave",
"Generator", "Suitcase", "Hammer", "Tent", "Anything!"
  ];

  useEffect(() => {
    const typingInterval = setInterval(() => {
      setCurrentItem(items[itemIndex].slice(0, charIndex + 1));
      if (charIndex < items[itemIndex].length - 1) {
        setCharIndex(charIndex + 1);
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          setItemIndex((itemIndex + 1) % items.length);
          setCharIndex(0);
        }, 1000);
      }
    }, 150);

    return () => clearInterval(typingInterval);
  }, [charIndex, itemIndex, items]);

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 576;

  return (
    <header>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${isMobile ? '/assets/img/frontpage/mobile_banner_for_sharing_economy.webp' : '/assets/img/frontpage/desktop_banner_for_sharing_economy.webp'})`,
        }}
      >
        <div className="hero-overlay">
          <div className="hero-content">
            <div className="hero-text">
              <h2 className="mb-2">Why buy? When you can</h2>
              <h1 className="mb-3">
                RentMy&nbsp;<span className="typewriter">{currentItem}</span>
              </h1>
            </div>
            <div className="hero-search">
              <WhatWhereSearch homepage={true} searchTerm="" />
            </div>
          </div>
          <div className="featured-logos">
            <span className="featured-by"><h3>Featured by</h3></span>
            <LogoStripWhite />
          </div>
        </div>
      </div>
    </header>
  );
}