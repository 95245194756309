import axios from "axios";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import { Container, Col, Row } from "reactstrap";
import Footer from "../components/layout/Footer";
import LogoStrip from "../components/home/LogoStrip";
import { PopularSlider } from "../components/home/PopularSlider";
import ContactForm from "../components/contact-form/ContactForm"; 
import MetaTags from "../components/SEO/MetaTags";

interface Media {
  system: { name: string };
  fields: { umbracoFile: { src: string } };
}

interface Content {
  system: { name: string };
  fields: {
    header1: string;
    body1: string;
    media1: Media[];
    header2: string;
    body2: string;
    media2: Media[];
    header3_1: string;
    body3_1: string;
    media3_1: Media[];
    header3_2: string;
    body3_2: string;
    media3_2: Media[];
    header3_3: string;
    body3_3: string;
    media3_3: Media[];
    header4: string;
    body4: string;
    media4: Media[];
  };
}

export function RepairCafe() {
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState(false);

  const REACT_APP_CMS_URL = process.env.REACT_APP_CMS_URL || "";
  const REACT_APP_CMS_REPAIR_CAFE = process.env.REACT_APP_CMS_REPAIR_CAFE || "";

  const getContent = async () => {
    setLoading(true);
    try {
      const res = await axios.get(REACT_APP_CMS_URL + REACT_APP_CMS_REPAIR_CAFE);
      setContent(res.data as Content);
    } catch (error) {
      console.error("Error fetching content: ", error);
    }
    setLoading(false);
  };

  const getImage = (media: Media[]) => {
    return media?.[0] ? REACT_APP_CMS_URL + media[0].fields.umbracoFile.src : "";
  };

  useEffect(() => {
    getContent();
  }, []);

  const renderMedia = (media: Media[]) => (
    <img
      alt={media?.[0]?.system?.name || "No image"}
      src={getImage(media)}
      className="img-fluid"
    />
  );

return (
    <>
        <MetaTags
            title="Repair Cafe | Fix Instead of Replace | RentMy"
            description="Join our Repair Cafe community to fix and extend the life of your items. Learn repair skills, reduce waste, and connect with repair experts."
            keywords="repair cafe, fix items, sustainability, community repairs, reduce waste"
            canonicalUrl="/repair-cafe"
        />
            
            {loading || !content ? (
                    <div className="spinner-container">
                            <SmallLoadingSpinner />
                    </div>
            ) : (

                    <Container className="mt-2 static-pages-wrapper">
                            {/* Section 1 */}
                            <Row className="section align-items-center">
                                    <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-start order-2 order-md-1">
                                            <h2>{content.fields.header1}</h2>
                                            {parse(content.fields.body1 || "No content available")}
                                    </Col>
                                    <Col xs={12} md={6} className="d-flex justify-content-center align-items-center order-1 order-md-2">
                                            {renderMedia(content.fields.media1)}
                                    </Col>
                            </Row>

                            {/* Section 2 */}
                            <Row className="section align-items-center">
                                    <Col xs={12} md={6} className="d-flex justify-content-center align-items-center order-1 order-md-1">
                                            {renderMedia(content.fields.media2)}
                                    </Col>
                                    <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-start order-2 order-md-2">
                                            <h2>{content.fields.header2}</h2>
                                            {parse(content.fields.body2 || "No content available")}
                                    </Col>
                            </Row>

                            {/* Section 3 */}
                            <Row className="section align-items-center">
                                    {[1, 2, 3].map((index) => (
                                            <Col key={index} xs={12} md={4} className="d-flex flex-column align-items-center text-center">
                                                    {renderMedia(content.fields[`media3_${index}`])}
                                                    <h2>{content.fields[`header3_${index}`]}</h2>
                                                    {parse(content.fields[`body3_${index}`] || "No content available")}
                                            </Col>
                                    ))}
                            </Row>

                            {/* Section 4 */}
                            <Row className="section align-items-center">
                                    <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
                                            {renderMedia(content.fields.media4)}
                                    </Col>
                                    <Col xs={12} md={6} className="d-flex flex-column align-items-start">
                                            <h2>{content.fields.header4}</h2>
                                            {parse(content.fields.body4 || "No content available")}
                                    </Col>
                            </Row>

                            {/* Footer */}
                            <>
                                    <Row className="contactform align-items-center">
                                            <Col xs={12}>
                                                    <h2>Thinking about starting your own Repair Cafe or looking for sponsorship of an existing one? Get in touch</h2>
                                            </Col>
                                    </Row>
                                    <Row className="section">
                                            <Col xs={12}>
                                                    <ContactForm />
                                            </Col>
                                    </Row>   
                                    <LogoStrip />   
                                    <Footer />
                            </>
                    </Container>
            )}
    </>
);
}