import { dayRateDisplay } from "../../utils/dayRateDisplay";

// Generate dynamic SEO metadata once the item data is loaded
const GenerateSeoMetadata = (item, itemDetails, location) => {
  if (!item || !itemDetails) return null;

  // Title: Include item name for better SEO
  const seoTitle = `${itemDetails.name}${
    itemDetails.approximateLocation?.city
      ? ` in ${itemDetails.approximateLocation.city}`
      : ""
  } for Rent | RentMy`;

  // Description: Include key details about the item
  const seoDescription = itemDetails.description
    ? `Rent ${itemDetails.name} in ${
        itemDetails.approximateLocation?.city
      } from £${dayRateDisplay(
        itemDetails.priceInfo.cheapestDailyPrice
      )}/day. ${itemDetails.description.substring(0, 120)}...`
    : `Rent ${itemDetails.name} in ${
        itemDetails.approximateLocation?.city
      } from £${dayRateDisplay(itemDetails.priceInfo.cheapestDailyPrice)}/day.`;

  // Keywords based on item details
  const seoKeywords = `rent ${itemDetails.name}, ${itemDetails.categories[0].path}, equipment rental, ${itemDetails.approximateLocation?.city} rentals`;

  // Canonical URL for this specific item
  const seoCanonicalUrl = location.pathname;

  // Use the first image from the item as OG image if available
  const seoOgImage =
    itemDetails.images && itemDetails.images.length > 0
      ? itemDetails.images[0].path
      : null;

  // Structured data for rich results (Product)
  const seoStructuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: itemDetails.name,
    description: itemDetails.description,
    image: itemDetails.images?.map((img) => img.path) || [],
    offers: {
      "@type": "Offer",
      price: itemDetails.priceInfo.cheapestDailyPrice,
      priceCurrency: "GBP",
    },
  };

  return {
    title: seoTitle,
    description: seoDescription,
    keywords: seoKeywords,
    canonicalUrl: seoCanonicalUrl,
    ogImage: seoOgImage,
    ogType: "product",
    structuredData: seoStructuredData,
  };
};

export default GenerateSeoMetadata;
