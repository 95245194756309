import * as React from "react";
import { Circle, GoogleMap } from "@react-google-maps/api";

export default function NonInteractiveMap({ itemDetails, height = "220px" }) {
  const { approximateLocation } = itemDetails;
  const location = {
    lat: approximateLocation?.latitude,
    lng: approximateLocation?.longitude,
  };
  return (
    <GoogleMap
      options={{
        disableDefaultUI: true,
        draggable: false,
        clickableIcons: false,
      }}
      mapContainerClassName="non-interactive-map"
      mapContainerStyle={{
        width: "100%",
        height,
      }}
      zoom={13}
      center={location}
    >
      <Circle
        options={{
          fillColor: "#EA554A33",
          fillOpacity: 0.8,
          strokeColor: "#EA554A",
          strokeWeight: 1,
        }}
        center={location}
        radius={450}
      />
    </GoogleMap>
  );
}
