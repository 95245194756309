import * as React from "react";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { dayRateDisplay } from "../../utils/dayRateDisplay";
import MarkdownModal from "../modal/MarkdownModal";
import BasicModal from "../common/BasicModal.tsx";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import IPIDWording from '../insurance-documents/IPIDWording';

export function InsuranceView({ itemDetails }) {
  const hasInsuranceQuote = itemDetails.insuranceQuote ?? false;
  const [insuranceIPIDModalOpen, setInsuranceIPIDModalOpen] = useState(false);
  const [insuranceModalOpen, setInsuranceModalOpen] = useState(false);

  const bannerTitle = itemDetails.requireInsurance
    ? "Insured Rental"
    : "Insure Your Rental";

  const openInsuranceModal = (e) => {
    e.preventDefault();
    setInsuranceModalOpen(true);
  };

  const openInsuranceIPIDModal = (e) => {
    e.preventDefault();
    setInsuranceIPIDModalOpen(true);
};

  return hasInsuranceQuote ? (
    <>
      <div className="inner-row item-insure-banner">
        <div>
          <h3>{bannerTitle}</h3>
          <p>
            <span>
              <strong>
                <FontAwesomeIcon icon={faCheckCircle} /> Avoid Unexpected Bills
              </strong>
              If something goes wrong, you won't be stuck covering hefty repairs
              or replacements.
            </span>
            <span>
              <strong>
                <FontAwesomeIcon icon={faCheckCircle} /> Free Up Your Funds
              </strong>
              With no deposit required, you can keep your money for what really
              matters.
            </span>
            <span>
              <strong>
                <FontAwesomeIcon icon={faCheckCircle} /> Rent with Peace of Mind
              </strong>
              Our coverage lets you focus on enjoying your rental—not worrying
              about worst-case scenarios.
            </span>

            <a href="#_" onClick={openInsuranceIPIDModal}>
              More Info <FontAwesomeIcon icon={faChevronCircleRight} />
            </a>
          </p>
        </div>
        <div className="item-insure-logo"></div>
      </div>

                <BasicModal
                headerClass={""}
                modalTitle={"Insurance IPID"}
                modalBody={<IPIDWording />}
                modalState={insuranceIPIDModalOpen}
                toggleModal={() => setInsuranceIPIDModalOpen(false)}
                 />
    </>
  ) : null;
}
