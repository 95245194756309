import {
  Card,
  CardBody,
  Col,
  Collapse,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import CurrencyInput from "react-currency-input-field";
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import { InputDescription } from "./Shared";
import { PricePeriod } from "../../api/rentMyApi";
import {
  BookingDaysFormProps,
  DailyPriceFormProps,
    DailyPriceSectionProps,
    DailyPriceDiscountFormProps,
    DiscountSectionProps,
  DepositToggleProps,
  ItemAvailabilitySectionProps,
  ItemValueInputProps,
  ReceiptToggleProps,
  RentalInsuranceSectionProps,
  SelectPurchaseDateProps,
  SetOriginalValueProps,
} from "../../models/interfaces/ItemCostAndValueInterface";

export function SelectPurchaseDate({
  t,
  itemPurchaseDate,
  setItemPurchaseDate,
  disabled,
}: SelectPurchaseDateProps) {
  return (
    <Col sm={4}>
      <FormGroup>
        <label htmlFor="item-listing-when-did-you-buy-it">
          {t("item_listing_cost_and_value_when_did_you_buy_it")}
        </label>
        <DatePicker
          showMonthYearPicker
          dateFormat={"MM/yyyy"}
          className={"form-control input-field"}
          selected={itemPurchaseDate}
          onChange={(date: Date) => setItemPurchaseDate(date)}
          maxDate={new Date()}
          disabled={disabled}
        />
        <FormFeedback>
          {t("item_listing_availability_daily_price_required")}
        </FormFeedback>
        <InputDescription
          text={t("item_listing_cost_and_value_when_did_you_buy_it_sub_title")}
        />
      </FormGroup>
    </Col>
  );
}

export function SetOriginalValue({
  t,
  originalValue,
  updateErrors,
  setOriginalValue,
  originalValueValidation,
  disabled,
}: SetOriginalValueProps) {
  return (
    <Col sm={4}>
      <FormGroup>
        <label htmlFor="item-listing-what-did-it-cost">
          {t("item_listing_cost_and_value_what_did_it_cost")}
        </label>
        <CurrencyInput
          id="item-listing-what-did-it-cost "
          prefix="&pound;"
          allowNegativeValue={false}
          allowDecimals={true}
          value={originalValue}
          onValueChange={(value) => {
            if (Number(value) < 0) {
              setOriginalValue(0);
            } else {
              if (typeof value === "string") {
                setOriginalValue(parseFloat(value));
              }
            }
            updateErrors("originalValue");
          }}
          className={`item-listing-form-input form-control ${
            originalValueValidation ? "is-invalid" : ""
          } input-field`}
          name="input-1"
          placeholder="£0"
          step={1}
          disabled={disabled}
        />
        <FormFeedback>
          {t("item_listing_cost_and_value_what_did_it_cost_wrong")}
        </FormFeedback>
        <InputDescription
          text={t("item_listing_cost_and_value_what_did_it_cost_sub_title")}
        />
      </FormGroup>
    </Col>
  );
}

export function ItemValueInput({
  t,
  itemValue,
  updateErrors,
  setItemValue,
  itemValueValidation,
  disabled,
}: ItemValueInputProps) {
  return (
    <Col sm={4}>
      <FormGroup>
        <label htmlFor="item-listing-what-would-it-cost-now">
          {t("item_listing_cost_and_value_what_would_it_cost_now")}
        </label>
        <CurrencyInput
          id="item-listing-what-would-it-cost-now"
          prefix="&pound;"
          decimalsLimit={2}
          value={itemValue}
          allowDecimals={true}
          allowNegativeValue={false}
          onValueChange={(value) => {
            if (Number(value) < 0) {
              setItemValue(0);
            } else {
              if (typeof value === "string") {
                setItemValue(parseFloat(value));
              }
            }
            updateErrors("pricePeriodDailyPriceIsIncorrect");
          }}
          placeholder="£0"
          className={`form-control item-listing-form-input-right ${
            itemValueValidation ? "is-invalid" : ""
          } input-field`}
          disabled={disabled}
        />
        <FormFeedback>
          {t("item_listing_cost_and_value_what_would_it_cost_now_wrong")}
        </FormFeedback>
        <InputDescription
          text={t(
            "item_listing_cost_and_value_what_would_it_cost_now_sub_title"
          )}
        />
      </FormGroup>
    </Col>
  );
}

export function DailyPriceSection({
  t,
  tempPriceView,
  setPricePeriod,
  pricePeriod,
  setTempPriceView,
  pricePeriodFormValidation,
  updatePricePeriodErrors,
}: DailyPriceSectionProps) {
  return (
    <>
      <DailyPriceForm
        t={t}
        tempPriceView={tempPriceView}
        setPricePeriod={setPricePeriod}
        pricePeriod={pricePeriod}
        setTempPriceView={setTempPriceView}
        pricePeriodFormValidation={pricePeriodFormValidation}
        updateErrors={updatePricePeriodErrors}
          />
      <TotalPriceBox dailyPrice={tempPriceView} />
    </>
  );
}

export function DiscountsSection({
    t,
    tempWeeklyPriceView,
    tempMonthlyPriceView,
    pricePeriodFormValidation,
    updatePricePeriodErrors,

    weeklyDiscount,
    setWeeklyDiscount,

    monthlyDiscount,
    setMonthlyDiscount,

    pricePeriod,
    setPricePeriod
}: DiscountSectionProps) {
    return (
        <>
            <DiscountsForm
                weeklyDiscount={weeklyDiscount}
                setWeeklyDiscount={setWeeklyDiscount}

                monthlyDiscount={monthlyDiscount}
                setMonthlyDiscount={setMonthlyDiscount}

                t={t}
                tempWeeklyPriceView={tempWeeklyPriceView}
                tempMonthlyPriceView={tempMonthlyPriceView}

                pricePeriodFormValidation={pricePeriodFormValidation}
                updateErrors={updatePricePeriodErrors}

                pricePeriod={pricePeriod}
                setPricePeriod={setPricePeriod}
            />
        </>
    );
}
export function ItemAvailabilitySection({
  tempMinDays,
  tempMaxDays,
  setPricePeriod,
  pricePeriod,
  setTempMinDays,
  setTempMaxDays,
  pricePeriodFormValidation,
  updatePricePeriodErrors,
}: ItemAvailabilitySectionProps) {
  return (
    <>
      <BookingDaysForm
        element="minimumBookingDays"
        title="Minimum"
        type={"minimum"}
        tempValue={tempMinDays}
        updatePricePeriod={setPricePeriod}
        pricePeriod={pricePeriod}
        setTempValue={setTempMinDays}
        pricePeriodFormValidation={pricePeriodFormValidation}
        updatePricePeriodErrors={updatePricePeriodErrors}
      />
      <BookingDaysForm
        element="maximumBookingDays"
        title="Maximum"
        type={"maximum"}
        tempValue={tempMaxDays}
        updatePricePeriod={setPricePeriod}
        pricePeriod={pricePeriod}
        setTempValue={setTempMaxDays}
        pricePeriodFormValidation={pricePeriodFormValidation}
        updatePricePeriodErrors={updatePricePeriodErrors}
      />
    </>
  );
}

export function BookingDaysForm({
  element,
  title,
  type,
  tempValue,
  setTempValue,
  updatePricePeriod,
  pricePeriod,
  pricePeriodFormValidation,
  updatePricePeriodErrors,
}: BookingDaysFormProps) {
  return (
    <Col sm={4}>
      <FormGroup className={type === "maximum" ? `additional-padding-2` : ""}>
        <label htmlFor="item-listing-availability">{`${title} days`}</label>
        <CurrencyInput
          id="item-listing-availability"
          decimalsLimit={0}
          allowDecimals={false}
          allowNegativeValue={false}
          value={tempValue}
          onValueChange={(value) => {
            if (typeof value === "string") {
              setTempValue(parseFloat(value));
            }
            const newPricePeriod = new PricePeriod({
              ...pricePeriod,
              [element]: tempValue ?? undefined,
            });
            updatePricePeriod(newPricePeriod);
            updatePricePeriodErrors(false, "minimumAndMaximumDateOverlap");
          }}
          className={`form-control ${
            type === "maximum" &&
            pricePeriodFormValidation &&
            pricePeriodFormValidation.length > 0 &&
            pricePeriodFormValidation[0].minimumAndMaximumDateOverlap
              ? "is-invalid"
              : ""
          } input-field`}
        />
        {type === "maximum" && (
          <FormFeedback>Minimum days cannot exceed maximum days</FormFeedback>
        )}
        <InputDescription
          text={`The ${type} number of days required for a rental booking (optional)`}
        />
      </FormGroup>
    </Col>
  );
}

export function DailyPriceForm({
  tempPriceView,
  setTempPriceView,
  t,
  setPricePeriod,
  pricePeriod,
  updateErrors,
  pricePeriodFormValidation,
}: DailyPriceFormProps) {
  return (
    <Col sm={4}>
      <FormGroup>
        <label htmlFor="item-listing-availability-daily-price">
            {t("item_listing_availability_daily_price")}
        </label>
        <CurrencyInput
          id="item-listing-availability-daily-price"
          prefix="&pound;"
          // decimalsLimit={2}
          allowDecimals={true}
          allowNegativeValue={false}
          value={tempPriceView}
          onValueChange={(value) => {
            if (typeof value === "string") setTempPriceView(value ?? "0");
            if (Number(tempPriceView) < 0) {
              const newPricePeriod: PricePeriod = new PricePeriod({
                ...pricePeriod,
                price: {
                  ...pricePeriod.price,
                  daily: 0,
                },
              });
              setPricePeriod(newPricePeriod);
            } else {
              const newPricePeriod: PricePeriod = new PricePeriod({
                ...pricePeriod,
                price: {
                  ...pricePeriod.price,
                  daily: Number(tempPriceView),
                },
              });
              setPricePeriod(newPricePeriod);
            }
            tempPriceView &&
              updateErrors(false, "pricePeriodDailyPriceIsIncorrect");
          }}
          placeholder="£0"
          className={`form-control ${
            pricePeriodFormValidation &&
            pricePeriodFormValidation.length > 0 &&
            pricePeriodFormValidation[0]?.pricePeriodDailyPriceIsIncorrect
              ? "is-invalid"
              : ""
          } input-field`}
        />
        <FormFeedback>
          {t("item_listing_availability_daily_price_required")}
        </FormFeedback>
        <InputDescription text={"Amount you will be paid per day."} />
      </FormGroup>
    </Col>
  );
}

export function DiscountsForm({
    weeklyDiscount,
    setWeeklyDiscount,

    monthlyDiscount,
    setMonthlyDiscount,
    t,

    tempWeeklyPriceView,
    tempMonthlyPriceView,

    updateErrors,
    pricePeriodFormValidation,

    pricePeriod,
    setPricePeriod
}: DailyPriceDiscountFormProps) {
    return (
        <>
        <Col sm={4}>
            <FormGroup>
                <label htmlFor="item-listing-availability-daily-price">
                    {"Weekly Discount"}
                </label>
                <CurrencyInput
                        id="item-listing-availability-daily-price"
                        suffix="%"
                    // decimalsLimit={2}
                    allowDecimals={true}
                    allowNegativeValue={false}
                        value={tempWeeklyPriceView}
                        onValueChange={(value) => {
                            if (value != null) {
                                setWeeklyDiscount(Number.parseInt(value));
                            }
                    }}
                    placeholder="0%"
                    className={`form-control ${pricePeriodFormValidation &&
                            pricePeriodFormValidation.length > 0 &&
                            pricePeriodFormValidation[0]?.pricePeriodDailyPriceIsIncorrect
                            ? "is-invalid"
                            : ""
                        } input-field`}
                />
                <FormFeedback>
                    {t("item_listing_availability_daily_price_required")}
                </FormFeedback>
                <InputDescription text={"Amount you will discount for a 7+ day booking"} />
            </FormGroup>
         </Col>

         <Col sm={4}>
            <FormGroup>
                <label htmlFor="item-listing-availability-daily-price">
                    {"Monthly Discount"}
                </label>
                <CurrencyInput
                        id="item-listing-availability-daily-price"
                        suffix="%"
                    // decimalsLimit={2}
                    allowDecimals={true}
                    allowNegativeValue={false}
                        value={tempMonthlyPriceView}
                    onValueChange={(value) => {
                        if (value != null) {
                            setMonthlyDiscount(Number.parseInt(value));
                        }
                    }}
                    placeholder="0%"
                    className={`form-control ${pricePeriodFormValidation &&
                        pricePeriodFormValidation.length > 0 &&
                        pricePeriodFormValidation[0]?.pricePeriodDailyPriceIsIncorrect
                        ? "is-invalid"
                        : ""
                        } input-field`}
                />
                <FormFeedback>
                    {t("item_listing_availability_daily_price_required")}
                </FormFeedback>
                <InputDescription text={"Amount you will discount for a 30+ day booking"} />
            </FormGroup>
            </Col>
        </>
    );
}

const addTwentyPercent = (amount: number) => +(amount * 1.2).toFixed(2);

export function TotalPriceBox({
  dailyPrice,
}: {
  dailyPrice: string | undefined;
}) {
  return (
    <Col sm={4}>
      <FormGroup className="additional-padding">
        {/*<label htmlFor="item-availability-daily-price">{" "}</label>*/}
        <CurrencyInput
          disabled={true}
          allowDecimals={true}
          allowNegativeValue={false}
          id="item-listing-availability-daily-price"
          prefix="&pound;"
          decimalsLimit={2}
          value={
            parseFloat(dailyPrice ?? "0")
              ? addTwentyPercent(parseFloat(dailyPrice ?? "0")).toFixed(2)
              : 0.0
          }
          onValueChange={() => console.log("")}
          className={`form-control`}
          placeholder="£0"
        />
        <InputDescription
          text={"Price the renter will pay which includes our charges."}
        />
      </FormGroup>
    </Col>
  );
}

export function DepositToggle({
  t,
  pricePeriod,
  setPricePeriod,
  updateErrors,
  fieldsValidation,
  hasDeposit,
  setHasDeposit,
}: DepositToggleProps) {
  return (
    <>
      <Col className="toggle-form-section" sm={12}>
        <h3 className="mb-0">
          {t("item_listing_security_deposit_description")}
        </h3>
        <label className="float-right custom-toggle">
          <input
            type="checkbox"
            checked={hasDeposit}
            onChange={() => {
              if (hasDeposit) {
                const newPricePeriod = new PricePeriod({
                  ...pricePeriod,
                  price: {
                    ...pricePeriod.price,
                    deposit: undefined,
                  },
                });
                setPricePeriod(newPricePeriod);
              }
              setHasDeposit(!hasDeposit);
            }}
          />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off={t("no")}
            data-label-on={t("yes")}
          />
        </label>
      </Col>

      {hasDeposit && (
        <Col sm={4}>
          <FormGroup>
            {/*<label*/}
            {/*    htmlFor="item-availability-booking-deposit">{t("item_listing_availability_hourly_booking_terms_deposit")}</label>*/}
            <CurrencyInput
              id="item-listing-availability-daily-price"
              prefix="&pound;"
              placeholder={"£0"}
              decimalsLimit={2}
              allowNegativeValue={false}
              allowDecimals={true}
              value={pricePeriod.price.deposit}
              onValueChange={(value) => {
                if (!value || Number(value) < 0) {
                  const newPricePeriod = new PricePeriod({
                    ...pricePeriod,
                    price: {
                      ...pricePeriod.price,
                      deposit: undefined,
                    },
                  });

                  setPricePeriod(newPricePeriod);
                } else {
                  const newPricePeriod = new PricePeriod({
                    ...pricePeriod,
                    price: {
                      ...pricePeriod.price,
                      deposit: parseFloat(value),
                    },
                  });
                  setPricePeriod(newPricePeriod);
                }
                updateErrors("deposit");
              }}
              className={`margin-top-10 form-control ${
                fieldsValidation.bookingDepositIsInvalid ? "is-invalid" : ""
              }`}
            />
            {fieldsValidation.bookingDepositIsInvalid && (
              <FormFeedback>
                {t("item_listing_booking_terms_invalid_deposit")}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      )}
    </>
  );
}

export function ReceiptToggle({
  t,
  receipt,
  setReceiptToggle,
}: ReceiptToggleProps) {
  return (
    <>
      <Col className="toggle-form-section" sm={12}>
        <h3 className="mb-0">{t("item_listing_receipt")}</h3>
        <label className="float-right custom-toggle">
          <input
            type="checkbox"
            checked={receipt}
            onChange={() => setReceiptToggle(!receipt)}
          />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off={t("no")}
            data-label-on={t("yes")}
          />
        </label>
      </Col>
      <Col sm={12}>
        <InputDescription text={t("item_listing_receipt_description")} />
      </Col>
    </>
  );
}

export function RentalInsuranceSection({
  t,
  isInsuraceToggleOpen,
  insuranceImage,
  toggleSetup,
  insuranceId,
  setChosenInsuranceId,
}: RentalInsuranceSectionProps) {
  return (
    <Card>
      <CardBody>
        <h2>{t("item_listing_rental_insurance")}</h2>
        <p style={{ maxWidth: "730px" }}>
          {t("item_listing_eligible_for_insurance")}{" "}
        </p>
        <p className="small mt-2">
          {t("for_more_information")}{" "}
          <span>
            <a
              href="https://www.protectmy.co.uk"
              target="blank"
              style={{ color: "#EA554A" }}
            >
              {t("click_here")}
            </a>
          </span>
          .
        </p>
        <Collapse isOpen={isInsuraceToggleOpen}>
          <hr className="mt-3 mb-3" />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className="mb-0">{t("item_listing_insurance_required")}</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="custom-toggle">
                <input
                  disabled={toggleSetup.disabled}
                  type="checkbox"
                  checked={toggleSetup.value}
                  onChange={() => {
                    const newValue = !toggleSetup.value;
                    toggleSetup.setValue(newValue);
                    setChosenInsuranceId &&
                      setChosenInsuranceId(newValue ? insuranceId : undefined);
                  }}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off={t("no")}
                  data-label-on={t("yes")}
                />
              </label>
            </div>
          </div>
        </Collapse>
      </CardBody>
    </Card>
  );
}
