import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export default function PricingBreakdown({
  itemDetails,
  priceIsLoading,
  priceObj,
  startDate,
  endDate,
  networkErrorText,
}) {
  const friendlyFormatDate = (date) => {
    return moment(date).format("ddd Do MMM");
  };
  const { t } = useTranslation();

  const getDayPriceAvg = () => {
    const startAt = new moment(startDate).startOf("day");
    const endAt = new moment(endDate).endOf("day").subtract(1, "day");
    const daysAndCost = [];

    for (let start = startAt; start < endAt; start.add(1, "day")) {
      const thisDay = start.format("YYYYMMDD");
      const thisDayCost = _.find(
        itemDetails.priceInfo.days,
        (v, k) => k === thisDay
      );
      daysAndCost.push({
        date: start.format("YYYYMMDD"),
        cost: thisDayCost.daily,
      });
    }
    const costsGrouped = _.groupBy(daysAndCost, (costs) => costs.cost);
    const countPerCost = _.map(_.keys(costsGrouped), (cost) => {
      return {
        cost: parseFloat(cost),
        count: costsGrouped[cost].length,
      };
    });
    let costString = "";
    _.each(countPerCost, (costCount) => {
      costString += `${costCount.count} day${
        costCount.count === 1 ? "" : "s"
      } at £${costCount.cost.toFixed(2)}`;
    });
    return costString;
  };

  return (
    <div className="booking-price-row">
      {priceIsLoading ? (
        <div className="generating-booking-price">
          <div className="skeleton-row"></div>
          <div className="skeleton-row"></div>
          <div className="skeleton-row"></div>
          <div className="skeleton-row"></div>
        </div>
      ) : priceObj ? (
        <div className="generated-booking-price">
          <div className="generated-booking-title">
            <h2>{t("item_detailed_confirmation_price_breakdown")}</h2>
            <span>
              {friendlyFormatDate(startDate)} - {friendlyFormatDate(endDate)}
            </span>
          </div>
          <ul>
            <li>
              <span className="price-key">{getDayPriceAvg()}</span>
              <span className="price-value">
                £{priceObj.paymentAmount.toFixed(2)}
              </span>
            </li>

            <li>
              <span className="price-key">{t("rent_my_fee")}</span>
              <span className="price-value">
                £{priceObj.serviceFeeAmount.toFixed(2)}
              </span>
            </li>

            {priceObj.insuranceQuote && (
              <>
                <li>
                  <span className="price-key">
                    Insurance
                    <span className="tiny-key">
                      Price includes £{priceObj.insuranceQuote.tax.toFixed(2)}{" "}
                      insurance premium tax
                    </span>
                  </span>
                  <span className="price-value">
                    £{priceObj.insuranceQuote.total.toFixed(2)}
                  </span>
                </li>
              </>
            )}
            <li>
              <span className="price-key total">
                {t("item_detailed_total")}
              </span>
              <span className="price-value"> £{priceObj.total.toFixed(2)}</span>
            </li>
          </ul>
        </div>
      ) : networkErrorText ? (
        <div className="dates-not-valid-help">
          <span className="prompt">{networkErrorText}</span>
        </div>
      ) : null}
    </div>
  );
}
