import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import { Container, Col, Row } from "reactstrap";
import Footer from "../components/layout/Footer";
import LogoStrip from "../components/home/LogoStrip";
import ContactForm from "../components/contact-form/ContactForm";
import MetaTags from "../components/SEO/MetaTags";

interface Media {
  system: { name: string };
  fields: { umbracoFile: { src: string } };
}

interface Content {
  system: { name: string };
  fields: {
    header1: string;
    body1: string;
    media1: Media[];
    header2: string;
    body2: string;
    media2: Media[];
  };
}

export function Investors() {
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_CMS_URL, REACT_APP_CMS_INVESTOR } = process.env;

  const getContent = async () => {
    setLoading(true);
    try {
      if (REACT_APP_CMS_URL && REACT_APP_CMS_INVESTOR) {
        const res = await axios.get(REACT_APP_CMS_URL + REACT_APP_CMS_INVESTOR);
        setContent(res.data as Content);
      } else {
        console.error("CMS URL or Investor endpoint is not defined");
      }
    } catch (error) {
      console.error("Error fetching content: ", error);
    }
    setLoading(false);
  };

  const getImage = (media: Media) => {
    return REACT_APP_CMS_URL + media.fields.umbracoFile.src;
  };

  useEffect(() => {
    getContent();
  }, []);

  const ImageSlider = ({ media }: { media: Media[] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
      if (window.innerWidth > 768) {
        intervalRef.current = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
        }, 1800);
      }

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }, [media.length]);

    const handleSwipe = (direction: "left" | "right") => {
      if (direction === "left") {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
      } else if (direction === "right") {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? media.length - 1 : prevIndex - 1
        );
      }
    };

    return (
      <div
        className="image-slider-wrapper position-relative"
        onTouchStart={(e) => {
          const startX = e.touches[0].clientX;
          const handleTouchEnd = (e: TouchEvent) => {
            const endX = e.changedTouches[0].clientX;
            if (startX - endX > 50) handleSwipe("left");
            if (startX - endX < -50) handleSwipe("right");
            document.removeEventListener("touchend", handleTouchEnd);
          };
          document.addEventListener("touchend", handleTouchEnd);
        }}
      >
        <div className="image-slider">
          {media.map((mediaItem, index) => (
            <div
              key={index}
              className={`image-slide ${
                index === currentIndex ? "active" : "inactive"
              }`}
            >
              <img
                src={getImage(mediaItem)}
                alt={mediaItem.system.name}
                className="img-fluid"
                loading="lazy"
              />
            </div>
          ))}
        </div>

        {media.length > 1 && (
          <div className="slider-dots">
            {media.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? "active" : ""}`}
                onClick={() => setCurrentIndex(index)}
              ></span>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderMedia = (media: Media[]) => {
    return media?.length > 1 ? (
      <ImageSlider media={media} />
    ) : (
      <img
        alt={media[0]?.system.name}
        src={getImage(media[0])}
        className="img-fluid"
      />
    );
  };

  return ( 
        <>
            <MetaTags
                title="Investor Relations | RentMy"
                description="Investment opportunities with RentMy. Learn about our growth strategy, financial performance, and vision for the future of rental marketplaces."
                keywords="RentMy investors, rental marketplace investment, investment opportunities, rental platform growth"
                canonicalUrl="/investors"
            />

            {loading || !content ? (
                <div className="spinner-container">
                    <SmallLoadingSpinner />
                </div>
            ) : (
                <Container className="mt-2 static-pages-wrapper">
                    {/* Section 1 */}
                    <Row className="section align-items-center">
                        <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-start order-2 order-md-1">
                            <h3>{content.fields.header1}</h3>
                            {parse(content.fields.body1 || "")}
                        </Col>
                        <Col
                            xs={12}
                            md={6}
                            className="d-flex justify-content-center align-items-center order-1 order-md-2"
                        >
                            {renderMedia(content.fields.media1)}
                        </Col>
                    </Row>

                    {/* Section 2 */}
                    <Row className="section align-items-center">
                        <Col
                            xs={12}
                            md={6}
                            className="d-flex justify-content-center align-items-center"
                        >
                            {renderMedia(content.fields.media2)}
                        </Col>
                        <Col xs={12} md={6} className="d-flex flex-column align-items-start">
                            <h3>{content.fields.header2}</h3>
                            {parse(content.fields.body2 || "No content available")}
                        </Col>
                    </Row>
                    
                    <Row className="contactform align-items-center">
                        <Col xs={12}>
                            <h2>Interested in investing? Contact our investor relations team today.</h2>
                        </Col>
                    </Row>
                    <Row className="section">
                        <Col xs={12}>
                            <ContactForm />
                        </Col>
                    </Row> 

                    <LogoStrip />
                    <Footer />
                </Container>
            )}  
        </>
  )
}