import { useTranslation } from "react-i18next";
import { Button, Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarRating from "./StarRating";
import { faChevronRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Checkbox } from "@mui/material";

interface ItemSnippetProps {
  toggleModal?: (value: boolean) => void;
  toggleImageSrc?: string;
  imageBelow?: boolean;
  wantInCard: boolean;
  fullText?: boolean; // Controls whether text is truncated or displayed in full
  icon?: {
    iconElement?: JSX.Element;
    userSetup?: {
      userProfilePicSrc: string;
      userId?: string;
      doesTitleRedirectOnClick?: boolean;
      doesTextRedirectOnClick?: boolean;
    };
    wantIconToBeCircle?: boolean;
  };
  text: {
    title: string;
    text: string;
    secondaryText: string;
    wantSecondaryTextToBeBold?: boolean;
    showRating?: boolean;
    bottomText?: string;
    sideText?: string;
    doesRedirectOnClick?: boolean;
    redirectToUserProfilePath?: string;
  };
  ratingSetup?: { overallAverage: number; reviewCount: number };
  buttonSetup?: {
    text: string;
    onClick: any;
    buttonOutline?: boolean;
    disabled?: boolean;
    infoSection?: boolean;
    inverted?: boolean;
  };
  secondaryButtonSetup?: {
    text: string;
    onClick: any;
    buttonOutline?: boolean;
    disabled?: boolean;
    infoSection?: boolean;
    inverted?: boolean;
  };
  toggleSetup?: { value: boolean; setValue: any; disabled?: boolean };
  universalToggleSetup?: { value: boolean; setValue: any; disabled?: boolean };
  badgesSetup?: {
    successText?: string;
    warningText?: string;
    defaultText?: string;
  };
  pricePeriodSetup?: {
    text: string;
    onClick: any;
    textDelete: string;
    onDeleteClick: any;
  };
  id?: string;
  center?: boolean;
  setChosenInsuranceId?: (id: string | undefined) => void;
  insuranceId?: string;
  style?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  insuranceUnderImageText?: string;
  confirmationPage?: boolean;
  outerStyle?: boolean;
  onInfoSectionClick?: () => void;
}
export default function ItemSnippet({
  center,
  confirmationPage,
  toggleModal,
  toggleImageSrc,
  imageBelow,
  wantInCard,
  fullText = false, // Default to false to maintain backward compatibility
  icon,
  text,
  ratingSetup,
  buttonSetup,
  secondaryButtonSetup,
  toggleSetup,
  universalToggleSetup,
  badgesSetup,
  pricePeriodSetup,
  id,
  setChosenInsuranceId,
  insuranceId,
  style,
  insuranceUnderImageText,
  imageStyle,
  outerStyle,
  onInfoSectionClick,
}: ItemSnippetProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToUserProfile = () => {
    if (icon && icon.userSetup?.userId) {
      navigate("/public/profile?id=" + icon.userSetup.userId);
    }
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--itemSnippetCursor",
      icon && icon.userSetup?.userId ? "pointer" : "default"
    );
  });

  // Define text styles based on fullText prop
  const getTextStyle = (): React.CSSProperties => {
    if (fullText) {
      return {
        overflow: "visible !important",
        display: "block !important",
        WebkitLineClamp: "initial !important",
        WebkitBoxOrient: "initial",
        maxHeight: "none !important",
        textOverflow: "clip !important",
        whiteSpace: "pre-wrap !important"
      };
    }
    return {}; 
  };
  
  const snippet = () => {
    return (
      <>
        {/* <div className={center ? "center-content" : ""}> */}
        {icon && icon.iconElement && icon.userSetup && (
          <div className="user-inside-item-image">
            <div className="vertically-align slightly-cornered-img ">
              {icon.iconElement}
            </div>
          </div>
        )}

        {icon && icon.iconElement && !icon.userSetup && (
          <div className="icon-in-circle icon-in-circle-default">
            {icon.iconElement}
          </div>
        )}
        {icon && !icon.iconElement && icon.userSetup && (
          <div
            className="icon-in-circle user-avatar snippet-div clickable"
            onClick={goToUserProfile}
          >
            <img
              width="100%"
              height="100%"
              alt="RentMy user profile image"
              src={icon.userSetup.userProfilePicSrc}
            />
          </div>
        )}

        <div
          className={`${
            center
              ? "info-section center-content text-align-center"
              : "info-section"
          } ${text.sideText ? "width-20" : ""}`}
          onClick={onInfoSectionClick}
        >
          {!imageBelow && toggleImageSrc && text.sideText && (
            <img
              src={toggleImageSrc}
              alt="Toggle Image"
              style={imageStyle}
              className={center ? "toggle-image-center" : "toggle-image-bigger"}
            />
          )}

          {!imageBelow && toggleImageSrc && !text.sideText && (
            <img
              src={toggleImageSrc}
              alt="Toggle Image"
              style={imageStyle}
              className={center ? "toggle-image-center" : "toggle-image"}
            />
          )}

          {text.title && (
            <h2
              className={text.title === "Underwritten by" ? "gray-title" : ""}
              style={
                text.redirectToUserProfilePath
                  ? { cursor: "pointer" }
                  : { cursor: "default" }
              }
              onClick={
                text.redirectToUserProfilePath ? goToUserProfile : undefined
              }
            >
              {text.title}
            </h2>
          )}

          {text.secondaryText && (
            <>
            <p className={`item-snippet-text mt-2 multiline-message ${fullText ? 'full-text-message' : ''}`} style={getTextStyle()} >
              {text.text}
            </p>

              {text.secondaryText && (
                <i>
                  <p
                    className={
                      "item-snippet-secondary-text fontsize-80-percent" +
                      (text.wantSecondaryTextToBeBold ? " bold" : "")
                    }
                  >
                    {text.secondaryText}
                  </p>
                </i>
              )}
            </>
          )}

          {imageBelow &&
            !confirmationPage &&
            toggleImageSrc &&
            !text.sideText && (
              <img
                src={toggleImageSrc}
                alt="Toggle Image"
                style={imageStyle}
                className={center ? "toggle-image-center" : "toggle-image"}
              />
            )}

          {insuranceUnderImageText && <p>{insuranceUnderImageText}</p>}

          {center && buttonSetup && (
            <Button
              disabled={buttonSetup.disabled}
              className={
                "btn btn-primary btn-lg mt-2" +
                (buttonSetup.inverted ? " btn-inverted" : "")
              }
              color={
                buttonSetup.buttonOutline ? "outline-secondary" : "secondary"
              }
              size="sm"
              onClick={buttonSetup.onClick}
              type="button"
              style={style}
            >
              {buttonSetup.text}
            </Button>
          )}

          {center && secondaryButtonSetup && (
            <Button
              disabled={secondaryButtonSetup.disabled}
              className={
                "btn btn-primary btn-lg mt-2" +
                (secondaryButtonSetup.inverted ? " btn-inverted" : "")
              }
              color={
                secondaryButtonSetup.buttonOutline
                  ? "outline-secondary"
                  : "secondary"
              }
              size="sm"
              onClick={secondaryButtonSetup.onClick}
              type="button"
              style={style}
            >
              {secondaryButtonSetup.text}
            </Button>
          )}

          {toggleModal && !text.bottomText && !text.sideText && (
            <p
              onClick={() => {
                toggleModal?.(true);
              }}
              className="small top-padding-4 underlined clickable width-120"
            >
              More Info
            </p>
          )}

          {ratingSetup && (
            <StarRating
              overallAverage={ratingSetup.overallAverage}
              reviewCount={ratingSetup.reviewCount}
            />
          )}

          {toggleModal && text.bottomText && (
            <div>
              <div className="flex-column">
                {
                  //     split text.bottomText by newline and make sure there is a linebreak after each
                  text.bottomText.split("\n").map((item, i) => {
                    return (
                      <p className="small " key={i}>
                        {item}
                      </p>
                    );
                  })
                }
                {/*<p className="small ">{text.bottomText}</p>*/}
              </div>
              {/*<div className='flex pt-1'>*/}
              {/*    <p onClick={() => { toggleModal?.(true); }}*/}
              {/*        className="small underlined clickable ">More Info</p>*/}
              {/*</div>*/}
            </div>
          )}

          {imageBelow &&
            confirmationPage &&
            toggleImageSrc &&
            !text.sideText && (
              <img
                src={toggleImageSrc}
                alt="Toggle Image"
                style={imageStyle}
                className={center ? "toggle-image-center" : "toggle-image"}
              />
            )}

          {confirmationPage && universalToggleSetup?.value && (
            <p>
              Your insurance covers you for the loss, theft or damage of this
              item. Enjoy your rental!
            </p>
          )}
        </div>

        <div
          className={center ? "right-section center-content" : "right-section"}
          style={
            text.sideText
              ? { width: "100%", justifyContent: "space-between" }
              : {}
          }
        >
          {badgesSetup?.successText && (
            <div className="badge-success-ours badge badge-pill">
              {badgesSetup.successText}
            </div>
          )}
          {badgesSetup?.warningText && (
            <div className="badge-warning-ours badge badge-pill">
              {badgesSetup.warningText}
            </div>
          )}
          {badgesSetup?.defaultText && (
            <div className="badge-default-ours badge badge-pill">
              {badgesSetup.defaultText}
            </div>
          )}

          {!center && buttonSetup && (
            <Button
              disabled={buttonSetup.disabled}
              className="item-snippet-button"
              color={buttonSetup.buttonOutline ? "outline-primary" : "primary"}
              size="sm"
              onClick={buttonSetup.onClick}
              type="button"
            >
              {buttonSetup.text}
            </Button>
          )}

          {toggleSetup && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="custom-toggle">
                <input
                  disabled={toggleSetup.disabled}
                  type="checkbox"
                  checked={toggleSetup.value}
                  onChange={() => {
                    const newValue = !toggleSetup.value;
                    toggleSetup.setValue(newValue);
                    setChosenInsuranceId &&
                      setChosenInsuranceId(newValue ? insuranceId : undefined);
                  }}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off={t("no")}
                  data-label-on={t("yes")}
                />
              </label>
            </div>
          )}
          {universalToggleSetup && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                sx={{ color: "#ea554a !important", fontSize: "2rem" }}
                color="success"
                checked={universalToggleSetup.value}
                onChange={(e) => {
                  const newValue = !universalToggleSetup.value;
                  universalToggleSetup.setValue(newValue);
                  setChosenInsuranceId &&
                    setChosenInsuranceId(newValue ? insuranceId : undefined);
                }}
              />
            </div>
          )}
          {text.sideText && (
            <div className="sidetext">
              <h2 className=""> Rent My Guarantee</h2>
              <p className="mt-1 ">{text.sideText}</p>
            </div>
          )}

          {toggleModal && text.sideText && (
            <p
              onClick={() => {
                toggleModal?.(true);
              }}
              className="top-padding-4 ml-5 underlined clickable "
            >
              More Info
            </p>
          )}

          {pricePeriodSetup && (
            <>
              <Button
                color="secondary"
                type="button"
                onClick={pricePeriodSetup.onDeleteClick}
                className="price-period-button"
              >
                <FontAwesomeIcon icon={faTrash} />
                <span className="btn-inner--text">
                  {pricePeriodSetup.textDelete}
                </span>
              </Button>
              <Button
                color="secondary"
                onClick={pricePeriodSetup.onClick}
                type="button"
                className="price-period-button"
              >
                <span className="btn-inner--text">{pricePeriodSetup.text}</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {wantInCard && (
        <Card className="item-snippet designOne" id={id}>
          <CardBody>{snippet()}</CardBody>
        </Card>
      )}

      {!wantInCard && (
        <div
          className={"item-snippet designTwo"}
          id={id}
          style={
            outerStyle
              ? {
                  background: "#f5f2f2",
                  padding: "10px",
                  marginTop: "12px",
                  marginBottom: "8px",
                  borderRadius: "8px",
                }
              : undefined
          }
        >
          {snippet()}
        </div>
      )}
    </>
  );
}