import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Col, Row } from "reactstrap";
import timeDisplay from "../../utils/timeDisplay";
import { useNavigate } from 'react-router-dom';
import Footer from "../../components/layout/Footer";
import { LoadingFullSide } from "../../components/common/LoadingFullScreen";
import MetaTags from "../../components/SEO/MetaTags";

export function Blog() {
    
    const blogURL = process.env.REACT_APP_BLOG_URL;
    const blogAPIKey = process.env.REACT_APP_BLOG_API_KEY;
    const navigate = useNavigate();
    const [loading, setLoading]: Array<any> = useState(true);
    const [blogPosts, setBlogPosts]: Array<any> = useState([]);

    const getContent = async() => {
        setLoading(true)
        try {
            const res = await axios.get(`${blogURL}/posts/?key=${blogAPIKey}&fetchBodies=false&fetchImages=true`);
            setBlogPosts(res.data.items)
        } catch(error) {
            console.error("Error fetching content: ", error);
        }
        setLoading(false)
    }

    const goToPost = (post) => {
        navigate(`/blog/${post.id}`)
    }

    useEffect(() => {
        getContent();
    }, []);

    return (
        <>
            <MetaTags
                title="Blog: Latest News, Tips & Rental Guides | RentMy"
                description="Explore RentMy's latest stories about equipment rentals, rental tips, how-to guides, and industry insights. Find valuable information for renters and owners."
                keywords="rental blog, equipment rental tips, rental guides, rental news, RentMy blog"
                canonicalUrl="/blog"
            />        
            {loading 
            ? <LoadingFullSide/> 
            : (
            <>
                <div className="height-wrapper">
                    <Container className="mt-4 static-pages-wrapper blog">
                        <Row>
                            <Col xs={12}>
                                <h1>Latest Stories & Rental Insights</h1>
                                <p className="blog-intro">Discover expert tips, industry trends, and helpful guides on renting and lending equipment and tools in your community.</p>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            {
                                blogPosts.map(post => {
                                    return (
                                        <Col xs={12} sm={6} md={6} lg={4} key={post.id}>
                                            <article className="single-entry-wrapper" onClick={() => goToPost(post)}>
                                                <div className="single-entry-image" style={{backgroundImage: `url(${post.images[0].url})`}} aria-label={`Featured image for ${post.title}`}></div>
                                                <div className="single-entry-data">
                                                    <h2 className="single-entry-title">{post.title}</h2> 
                                                    <time className="publish-date" dateTime={post.published}>{timeDisplay(post.published)}</time>
                                                </div>                      
                                                <div className="single-entry-publisher">
                                                    <div className="left">
                                                        <div className="publisher-image" style={{backgroundImage: `url(${post.author.image.url})`}} aria-label={`${post.author.displayName} profile picture`}></div>
                                                    </div>
                                                    <div className="right">
                                                        <span className="published-by-text">Published by</span>
                                                        <span className="published-name-text"> {post.author.displayName}</span>
                                                    </div>
                                                </div>
                                            </article>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>  
                </div>
                <Footer />
            </>
            )}
        </>

    );
}