// Guarantee.tsx
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import { Container, Col, Row } from "reactstrap";
import Footer from "../components/layout/Footer";
import { ExploreTopCategories } from "../components/home/ExploreTopCategories";
import LogoStrip from "../components/home/LogoStrip";
import MetaTags from "../components/SEO/MetaTags";

interface Media {
  system: { name: string };
  fields: { umbracoFile: { src: string } };
}

interface Content {
  system: { name: string };
  fields: {
    header1: string;
    body1: string;
    header2_1: string;
    header2_2: string;
    media2_1: Media[];
    body3: string;
    header3: string;
  };
}

export function InsuranceExplainer() {
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_CMS_URL, REACT_APP_CMS_GUARANTEE } = process.env;

  // Fetch content
  const getContent = async () => {
    setLoading(true);
    try {
      if (REACT_APP_CMS_URL && REACT_APP_CMS_GUARANTEE) {
        const res = await axios.get(REACT_APP_CMS_URL + REACT_APP_CMS_GUARANTEE); // NEW: c370fde9-1523-4579-9fc0-e0e52529e85e
        setContent(res.data as Content);
      } else {
        console.error("CMS URL or Guarantee endpoint is not defined");
      }
    } catch (error) {
      console.error("Error fetching content: ", error);
    }
    setLoading(false);
  };

  // Helper to build image URL
  const getImage = (media: Media) =>
    `${REACT_APP_CMS_URL}${media.fields.umbracoFile.src}`;

  useEffect(() => {
    getContent();
  }, []);

  // Simple slider if there's more than one image
  const ImageSlider = ({ media }: { media: Media[] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
      // Auto-slide only on desktop
      if (window.innerWidth > 768 && media.length > 1) {
        intervalRef.current = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
        }, 1800);
        return () => clearInterval(intervalRef.current as NodeJS.Timeout);
      }
    }, [media]);

    // Optional swipe on mobile
    const handleSwipe = (direction: "left" | "right") => {
      if (direction === "left") {
        setCurrentIndex((prev) => (prev + 1) % media.length);
      } else {
        setCurrentIndex((prev) => (prev === 0 ? media.length - 1 : prev - 1));
      }
    };

    return (
      <div
        className="image-slider-wrapper position-relative"
        onTouchStart={(e) => {
          const startX = e.touches[0].clientX;
          const handleTouchEnd = (endEvent: TouchEvent) => {
            const endX = endEvent.changedTouches[0].clientX;
            if (startX - endX > 50) handleSwipe("left");
            if (startX - endX < -50) handleSwipe("right");
            document.removeEventListener("touchend", handleTouchEnd);
          };
          document.addEventListener("touchend", handleTouchEnd);
        }}
      >
        <div className="image-slider">
          {media.map((mediaItem, i) => (
            <div
              key={i}
              className={`image-slide ${i === currentIndex ? "active" : "inactive"}`}
            >
              <img
                src={getImage(mediaItem)}
                alt={mediaItem.system.name}
                className="img-fluid"
                loading="lazy"
              />
            </div>
          ))}
        </div>

        {media.length > 1 && (
          <div className="slider-dots">
            {media.map((_, i) => (
              <span
                key={i}
                className={`dot ${i === currentIndex ? "active" : ""}`}
                onClick={() => setCurrentIndex(i)}
              ></span>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderMedia = (media: Media[]) => {
    if (!media || media.length === 0) return null;
    if (media.length > 1) {
      return <ImageSlider media={media} />;
    }
    // Single image, no slider
    return (
      <img
        alt={media[0].system.name}
        src={getImage(media[0])}
        className="img-fluid"
      />
    );
  };

  if (loading || !content) {
    return (
      <div className="spinner-container">
        <SmallLoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <MetaTags
        title="RentMy Insurance | Protection For Your Rentals"
        description="Learn about the RentMy insurance options that protect both renters and owners. Get peace of mind with our secure rental marketplace."
        keywords="rental insurance, insurance, rental protection, equipment insurance, RentMy Insurance"
        canonicalUrl="/insurance-explainer"
      />

      <div className="height-wrapper">
        <Container className="mt-4 static-pages-wrapper">
          <Row>
            <Col
              xs={{ offset: 0, size: 12 }}
              sm={{ offset: 2, size: 8 }}
            >
              <article className="insurance-explainer single-page-white">
                <h1 className="title">{content.fields.header1}</h1>
                
                <div className="single-page-body">
                  <div className="content-section">
                    {parse(content.fields.body1 || "")}
                  </div>
                  
                  <div className="content-section">
                    {renderMedia(content.fields.media2_1)}
                  </div>
                  
                  <div className="content-section logo-section">
                    <h3>{content.fields.header2_1}</h3>
                    <div>{parse(content.fields.header2_2 || "")}</div>
                  </div>
                  
                  <div className="content-section">
                    <h3>{content.fields.header3}</h3>
                    <div>{parse(content.fields.body3 || "")}</div>
                  </div>
                </div>
              </article>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}