import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { IConfig, ItemClient, ItemPageClient } from "../api/rentMyApi";
import { useLocation, useNavigate } from "react-router-dom";
import ItemCarousel from "../components/item-main/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDirections, faHomeUser } from "@fortawesome/free-solid-svg-icons";
import { dayRateDisplay } from "../utils/dayRateDisplay";
import NonInteractiveMap from "../components/item-main/NonInteractiveMap";
import { faMessage, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { gotoMessagesFromItemDetailedPage } from "../components/common/MessageUrlFormatting";
import { useUserContext } from "../context/UserContext";
import { InsuranceView } from "../components/item-main/InsuranceView";
import BookingArea from "../components/item-main/BookingArea";
import { useWindowSize, MAIN_BREAKPOINT } from "../utils/WindowSize";
import OtherItems from "../components/item-main/OtherItems";
import Reviews from "../components/item-main/Reviews";
import { useTranslation } from "react-i18next";
import MetaTags from "../components/SEO/MetaTags";
import GenerateSeoMetadata from "../components/SEO/SingleItemMetaData";

export function ItemMain() {
  const { REACT_APP_API_ENDPOINT: endpoint } = process.env;
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tabs = [
    {
      id: "items",
      value: t("item_detailed_other_items"),
    },
    {
      id: "reviews",
      value: t("my_profile_reviews"),
    },
  ];

  const { user: currentlyLoggedInUser } = useUserContext();
  const calendarRef = useRef();
  const reviewsRef = useRef();
  const [item, setItem] = useState(null);
  const [itemDetails, setItemDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const [otherItemCount, setOtherItemCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [reviews, setReviews] = useState(null);

  const { width } = useWindowSize();
  const isMobileView = width < MAIN_BREAKPOINT;

  const getSafeUrl = () => {
    const url = location.pathname.split("/");
    return url.slice(2, url.length).join("/");
  };

  const getItem = async () => {
    const itemClient = new ItemPageClient(new IConfig("notoken"), endpoint);
    const item = await itemClient.itemUrl(getSafeUrl());

    const itemDetailClient = new ItemClient(new IConfig("notoken"), endpoint);
    const itemDetails = await itemDetailClient.detailed24(item.id);

    const reviewsResponse = await itemDetailClient.detailed27(item.id);
    const reviews = reviewsResponse?.data || [];

    setReviews(reviews);
    setReviewCount(reviews.length);

    setItem(item);
    setItemDetails(itemDetails);
  };

  const scrollToCalendar = () => {
    if (!calendarRef.current) return;
    calendarRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const changeTab = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab.id);
  };

  const navigateTo = (e, link) => {
    e.preventDefault();
    navigate(link);
  };

  const goToReviews = (e) => {
    e.preventDefault();
    if (!reviewsRef.current) return;
    setActiveTab("reviews");
    reviewsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const openDirections = (e) => {
    e.preventDefault();
    window.open(
      `https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=${itemDetails.approximateLocation?.latitude},${itemDetails.approximateLocation?.longitude}`
    );
  };

  const getMessageLink = () => {
    return `/account/messages/${gotoMessagesFromItemDetailedPage(
      itemDetails.id,
      itemDetails.name,
      currentlyLoggedInUser.id
    )}/${itemDetails.user.id}`;
  };

  const getStat = (tab) =>
    tab.id === "reviews" ? reviewCount : otherItemCount;

  useEffect(() => {
    getItem();
  }, []);

  const seoMetadata = itemDetails
    ? GenerateSeoMetadata(item, itemDetails, location)
    : {};

  return (
    <>
      {item ? (
        <>
          {seoMetadata && <MetaTags {...seoMetadata} />}
          <Container className="item-main">
            <Row>
              <Col md={12} lg={8}>
                <ItemCarousel item={itemDetails} />
                <div className="single-item-main-details">
                  <h1>{itemDetails.name}</h1>
                  <span className="single-item-breadcrumb">
                    {itemDetails.categories[0].path}
                  </span>
                  <div className="single-item-price">
                    <span className="small-text">from</span>
                    <span className="price-text">
                      £
                      {dayRateDisplay(itemDetails.priceInfo.cheapestDailyPrice)}
                      <span className="day-text">
                        /{t("day").toLowerCase()}
                      </span>
                    </span>
                    <Button className="book-button" onClick={scrollToCalendar}>
                      {t("book_now")}
                    </Button>
                  </div>

                  <div className="single-item-description">
                    <p>{item.description}</p>
                  </div>

                  <div className="divider"></div>

                  <InsuranceView itemDetails={itemDetails} />
                </div>{" "}
                <div className="inner-row condensed-user-row">
                  {itemDetails.user.profileImage && (
                    <div className="row-left">
                      <div
                        className="renters-avatar"
                        style={{
                          backgroundImage: `url("${itemDetails.user.profileImage?.path}")`,
                        }}
                      ></div>
                    </div>
                  )}
                  <div className="content-right">
                    <span className="small-text">
                      {t("item_detailed_shared_by")}
                    </span>
                    <div className="user-meta">
                      <h2>{itemDetails.user.name}</h2>
                      <p className="small-text">
                        &nbsp;in {itemDetails.approximateLocation?.city}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="renter-location-container"
                  onClick={(e) => openDirections(e)}
                >
                  <NonInteractiveMap itemDetails={itemDetails} />
                </div>
                <div className="renter-actions-container">
                  <a
                    href="#_"
                    onClick={(e) =>
                      navigateTo(e, `/public/profile?id=${itemDetails.user.id}`)
                    }
                  >
                    <FontAwesomeIcon icon={faHomeUser} />
                    <span>{t("item_listing_view_profile")}</span>
                  </a>
                  <a href="#_" onClick={(e) => navigateTo(e, getMessageLink())}>
                    <FontAwesomeIcon icon={faMessage} />
                    <span>{t("item_listing_send_message")}</span>
                  </a>
                  <a href="#_" onClick={(e) => openDirections(e)}>
                    <FontAwesomeIcon icon={faDirections} />
                    <span>{t("item_listing_get_directions")}</span>
                  </a>
                  <a href="#_" onClick={(e) => goToReviews(e)}>
                    <FontAwesomeIcon icon={faStarHalfAlt} />
                    <span>{t("item_listing_read_reviews")}</span>
                  </a>
                </div>
                <div className="divider"></div>
                {isMobileView && (
                  <BookingArea
                    itemDetails={itemDetails}
                    calendarRef={calendarRef}
                  />
                )}
                <div className="divider" ref={reviewsRef}></div>
                <div className="renters-items-and-reviews">
                  <h3>
                    {t("more_from")} {itemDetails.user.name}
                  </h3>

                  <div className="general-tab-navigation">
                    {tabs.map((tab) => {
                      const isActive = activeTab === tab.id;
                      const stat = getStat(tab);
                      return (
                        <a
                          key={tab.id}
                          href="#_"
                          onClick={(e) => changeTab(e, tab)}
                          className={isActive ? "active" : ""}
                        >
                          {stat} {tab.value}
                        </a>
                      );
                    })}
                  </div>
                  {activeTab === "items" ? (
                    <OtherItems
                      userId={itemDetails.user.id}
                      setOtherItemCount={setOtherItemCount}
                      limit={4}
                    />
                  ) : (
                    <Reviews reviews={reviews} />
                  )}
                </div>
              </Col>
              {!isMobileView && (
                <Col sm={4}>
                  <BookingArea
                    itemDetails={itemDetails}
                    calendarRef={calendarRef}
                  />
                </Col>
              )}
            </Row>
          </Container>
        </>
      ) : null}
    </>
  );
}
