
import { useEffect, useState } from 'react';
import { Col, Container, Row, Input, Button } from 'reactstrap';
import { BookingClient, BookingCreateDto, BookingPricingDto, CurrencyIsoCode, IConfig, ItemClient, ItemPublicDto, StripeCheckoutRedirects, VoucherClient } from '../api/rentMyApi';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import NonInteractiveMap from '../components/item-main/NonInteractiveMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronCircleRight, faChevronRight, faFileCircleCheck, faMapPin, faSpinner, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@mui/material';
import moment from 'moment';
import BasicModal from '../components/common/BasicModal';
import PricingBreakdown from '../components/item-main/PricingBreakdown';
import PolicyWording from '../components/insurance-documents/PolicyWording';
import IPIDWording from '../components/insurance-documents/IPIDWording';


import {
    flexibleCancellationDescription,
    moderateCancellationDescription,
    strictCancellationDescription,
  } from  '../components/item/ItemListingConstantsAndDefaults';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import InsuranceToggle from '../components/checkout/InsuranceToggle';

export default function Checkout(props) {
    const { REACT_APP_API_ENDPOINT: endpoint } = process.env;
    const { getAccessTokenSilently } = useAuth0();

    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const [itemDetails, setItemDetails] = useState<ItemPublicDto>();
    const [priceObj, setPriceObj] = useState<BookingPricingDto>();
    const [networkErrorText, setNetworkErrorText] = useState(null);
    const [priceIsLoading, setPriceIsLoading] = useState(false);

    const [insuranceChosen, setInsuranceChosen] = useState(false);
    const [cancellationPolicyModalOpen, setCancellationPolicyModalOpen] =
        useState(false);
    
    const [discountCodeText, setDiscountCodeText] = useState("");
    const [activeDiscountCode, setActiveDiscountCode] = useState<string | null>(null);
    const [acceptedInsuranceTCS, setAcceptedInsuranceTCS] = useState(false);
    const [bookingLoading, setBookingLoading] = useState(false);

        const [insurancePolicyWordingModalOpen, setInsurancePolicyWordingModalOpen] = useState(false);
        const [insuranceIPIDModalOpen, setInsuranceIPIDModalOpen] = useState(false);

        
    const startDate = params.get("start-date") || null;
    const endDate = params.get("end-date") || null;
    const cancelId = params.get("cancel-id") || null;

    const { t } = useTranslation();
    const hasInsurancePolicy = itemDetails?.insuranceQuote;
    const needsToAcceptTCS = itemDetails?.requireInsurance || (!itemDetails?.requireInsurance && insuranceChosen)

    const returnCorrectCancellationBody = (cancellationPolicy) => {
        if (cancellationPolicy === "Flexible") {
          return flexibleCancellationDescription();
        }
        if (cancellationPolicy === "Moderate") {
          return moderateCancellationDescription();
        }
        if (cancellationPolicy === "Strict") {
          return strictCancellationDescription();
        }
        return <></>;
    };

    const firstImage = () => {
        if(itemDetails && itemDetails.images) {
            if(Array.isArray(itemDetails.images) && itemDetails.images.length > 0) {
                return itemDetails.images[0].path
            } else {
                return null
            }
        } else {
            return null
        }
    }


    const getItem = async() => {
        const itemId = params.get("id");
        if(!itemId) return;
        const itemDetailClient = new ItemClient(new IConfig("notoken"), endpoint);
        const itemDetails = await itemDetailClient.detailed24(itemId);
        setItemDetails(itemDetails);
    }

    const getFormattedDate = (date) => {
        if(!date) return null;
        const parsedDate = moment(date);
        if(!parsedDate.isValid()) return null;
        return parsedDate.format("ddd Do MMM")
    }

    const handleDiscountInput = (e) => {
        setDiscountCodeText(e.target.value)
    }

    const cancelFromId = async () => {
        if(!cancelId) return;
        const token = await getAccessTokenSilently();
        const bookingClient = new BookingClient(
            new IConfig(token),
            endpoint
        );
        await bookingClient.cancel(cancelId)        
    }

    const checkPrice = async () => {
        if (!itemDetails || !startDate || !endDate) return;

        const token = await getAccessTokenSilently();

        const bookingCreateDto = new BookingCreateDto({
            active: true,
            itemId: itemDetails.id,
            startingDate: moment(startDate).toDate(),
            endingDate: moment(endDate).toDate(),
            isHourly: false,
            currency: CurrencyIsoCode.GBP,
            vouchers: activeDiscountCode ? [activeDiscountCode] : undefined,
            referralCode: undefined,
            insuranceProductId: itemDetails.requireInsurance || insuranceChosen
                ? itemDetails.insuranceQuote.productId
                : undefined,
        });

        const config = (() => {
            if (bookingCreateDto.vouchers && bookingCreateDto.vouchers.length > 0 && token) {
                return new IConfig(token);
            }
            return new IConfig("notoken");
        })();

        const bookingClient = new BookingClient(
            config,
            endpoint
        );

        setPriceIsLoading(true);
    
        try {
            const price = await bookingClient.publicPricingCheck(bookingCreateDto);

          setPriceObj(price);
        } catch (err: any) {
           setNetworkErrorText(err.response);
        }
    
        setTimeout(() => {
          setPriceIsLoading(false);
        }, 500);
    };


    const handleAddDiscountCode = async () => {
        const voucherClient = new VoucherClient(new IConfig('notoken'), endpoint);
        let response;
        try {
         response = await voucherClient.fromCode(discountCodeText)
         if(response && response.code) {
            setActiveDiscountCode(response.id)
        }
        } catch(err) {
            console.log("err?? ", err);
        }
    }

    const toggleAcceptedInsuranceTCS = (e) => {
        if(bookingLoading) return;
        setAcceptedInsuranceTCS(e.target.checked)
    }

    const removeDiscountCode = () => {
        setActiveDiscountCode(null);
        setDiscountCodeText("");
    }

    const handleInsuranceToggle = (e) => {
        setInsuranceChosen(e.target.checked)
    }
    
    const openCancellationPolicyModal = (e) => {
        e.preventDefault();
        setCancellationPolicyModalOpen(true);
      };
    
   
    
    const openInsurancePolicyWordingModal = (e) => {
        e.preventDefault();
        setInsurancePolicyWordingModalOpen(true);
    };
    
    const openInsuranceIPIDModal = (e) => {
        e.preventDefault();
        setInsuranceIPIDModalOpen(true);
    };

      const backToItem = (e) => {
        e.preventDefault();
        window.location.href = "/item/" + itemDetails?.url;
      }

      const placeBooking = async () => {

        if(!itemDetails) return null;
        if(!acceptedInsuranceTCS && needsToAcceptTCS) {
            toast.error("Please accept the insurance terms and conditions to place your booking");
            return;
        }

        setBookingLoading(true)

        const token = await getAccessTokenSilently();
        const bookingClient = new BookingClient(
            new IConfig(token),
            endpoint
        );

        const bookingCreateDto = new BookingCreateDto({
            active: true,
            itemId: itemDetails.id,
            startingDate: moment(startDate).toDate(),
            endingDate: moment(endDate).toDate(),
            isHourly: false,
            currency: CurrencyIsoCode.GBP,
            vouchers: activeDiscountCode ? [activeDiscountCode] : undefined,
            referralCode: undefined,
            insuranceProductId: itemDetails.requireInsurance || insuranceChosen
              ? itemDetails.insuranceQuote.productId
              : undefined,
          });
      
          try {
            const response = await bookingClient.createPOST(true, bookingCreateDto);
            if(!response.id) {
                setBookingLoading(false);
                toast.error("There was an error making this booking, please contact support");
                return;
            }
            const successResponseParams = itemDetails?.instantBooking
                ? '&view=booking&handover=false'
                : '&view=booking&handover=false&showConfirmationToaster=true';

            const successResponse = window.location.origin + '/account/my-rentals?id=' + response.id + successResponseParams;
            const cancelResponse = window.location.href + '&cancel-id=' + response.id;
            const stripeCheckoutRedirects = new StripeCheckoutRedirects({
                cancel: cancelResponse,
                success: successResponse,
            });

            bookingClient.checkout(response.id, undefined, stripeCheckoutRedirects)
                .then((response) => {
                    window.location.href = response;
                })
                .catch(async () => {                    
                    await bookingClient.cancel(response.id);
                    toast.error('There was an error making this booking, please contact support');
                    setBookingLoading(false);
                });
          } catch (err: any) {
             toast.error(err.response);
             console.log(err)
             setBookingLoading(false);
          }
      }
      

    useEffect(() => {
        if(!cancelId) return () => {}
        cancelFromId()
      }, [cancelId]);

    useEffect(() => {
        if(!itemDetails || !startDate || !endDate) return () => {}
        checkPrice()
    }, [itemDetails, insuranceChosen, activeDiscountCode])

    useEffect(() => {
        getItem();
        cancelFromId()
    }, [])

    return (
        <Container className={`${bookingLoading ? 'opacity-6' : ''}`}>
            <Row>
                <Col sm={1}>
                
                </Col>
                <Col sm={10}>
                
            <Row>
                <Col sm={4}>
                {
                    itemDetails && (
                        <div className="item-main">
                        <div className="single-item-main-details">
                            {
                                firstImage() && (
                                    <div className="item-image-thumbnail" style={{
                                        // @ts-ignore
                                        backgroundImage: `url('${decodeURI(firstImage())}')`
                                    }}></div>
                                )                          
                            }

                            <h3>{itemDetails.name}</h3>
                            <div className="inner-row condensed-user-row">
                                {itemDetails.user.profileImage && (
                                    <div className="row-left">
                                    <div
                                        className="renters-avatar"
                                        style={{
                                            backgroundImage: `url("${itemDetails.user.profileImage?.path}")`,
                                        }}
                                    ></div>
                                    </div>
                                )}
                                <div className="content-right">
                                    <span className="small-text">
                                        {t("item_detailed_shared_by")}
                                    </span>
                                    <div className="user-meta">
                                        <h2>{itemDetails.user.name}</h2>
                                        <p className="small-text">
                                            &nbsp;in {itemDetails.approximateLocation?.city}
                                        </p>
                                    </div>
                                </div>
                            </div> 
                              <div
                                className="renter-location-container smaller">
                                <NonInteractiveMap itemDetails={itemDetails} height={"140px"}/>
                            </div>
                            <div className="exact-location-wrapper">
                                <div className="location-icon-wrapper">
                                    <FontAwesomeIcon icon={faMapPin}/>
                                </div>
                                <span>Exact location will be shared once booking is confirmed</span>
                            </div>
                        </div>
                    </div>
                    )
                }
                </Col>
                <Col sm={8}>

                    <div className="divider"></div>

                    <div className="checkout-panel first">
                        <div className="checkout-header">
                          <h3>Booking Dates</h3>
                        </div>
                        <div className="checkout-dates">
                            <a href="#_" className="change-dates-btn" onClick={backToItem}>
                                Change Dates?
                            </a>
                            <div className="chosen-dates">
                                <span>{getFormattedDate(startDate)}</span>
                                <FontAwesomeIcon icon={faChevronRight} />
                                <span>{getFormattedDate(endDate)}</span>
                            </div>
                            <span className="text-helper">Unless otherwise agreed with the Sharer, you must return the item by 9am on {getFormattedDate(endDate)}</span>
                        </div>
                    </div>

                    <div className="divider"></div>

                    <div className="checkout-panel">
                        <div className="checkout-header">
                          <h3>Discount Code</h3>
                          <span className="text-helper">If it's your first time renting ask for a referral code from the owner or a friend for £10 off!</span>
                        </div>
                        <div className="discount-code-wrapper">
                        <Row>
                            <Col xs={8}>
                            <Input
                                disabled={activeDiscountCode != null}
                                name="voucher-code"
                                className="input-field"
                                placeholder={t("enter_discount_code")}
                                onChange={handleDiscountInput}
                                value={discountCodeText}
                                />
                            </Col>
                            <Col xs={4}>
                            {
                                activeDiscountCode ? (
                                    <Button
                                        color="primary"
                                        onClick={removeDiscountCode}
                                     >
                                        Remove
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={discountCodeText === ""}
                                        color="primary"
                                        onClick={handleAddDiscountCode}
                                    >
                                        {t("apply")}{" "}
                                    </Button>
                                )
                            }
                                
                            </Col>
                        </Row>

                        </div>
                    </div>

                    <div className="divider"></div>
                        {itemDetails?.insuranceQuote && (
                            <div className="checkout-insurance-banner">
                                <div className="checkout-insurance-banner-info">
                                    <div className="checkout-insurance-banner-logo">
                                        <span>Provided by</span>
                                    </div>
                                        <h3>{itemDetails?.requireInsurance ? "Insured Rental" : "Insure Your Rental"}</h3>

                                        <p className="sub-header">Rent items with peace of mind and added protection</p>

                                        <div className="divide"></div>

                                        <p><strong>What's Covered?</strong></p>

                                        <p className="bullets">
                                            <span>
                                                <strong>
                                                    <FontAwesomeIcon icon={faCheckCircle} /> Accidental Damage:{" "}
                                                </strong>
                                                Covered for life's mishaps.
                                            </span>
                                            <span>
                                                <strong>
                                                    <FontAwesomeIcon icon={faCheckCircle} /> Theft:{" "}
                                                </strong>
                                                Stay secure if the worst happens
                                            </span>
                                            <span>
                                                <strong>
                                                    <FontAwesomeIcon icon={faCheckCircle} /> No Excess:{" "}
                                                </strong>
                                                No hidden costs or surprises
                                            </span>
                                        </p>
                                        <div className="divide"></div>
                                        <p><strong>What's Not Covered?</strong></p>
                                        <p className="bullets">
                                            <span>
                                                <FontAwesomeIcon icon={faXmarkCircle} className="red" /> Normal wear and tear, and any deliberate harm
                                            </span>
                                        </p>
                                        <div className="divide"></div>
                                        <p className="insure-meta">
                                        By adding this insurance you confirm that you are a UK resident, accept it is your responsibility to read and understand the IPID and Policy Wording, and ensured this product meets your needs.
                                        </p>
                                        <div className="divide"></div>

                        
                                        <InsuranceToggle itemDetails={itemDetails} insuranceChosen={insuranceChosen} handleInsuranceToggle={handleInsuranceToggle} />
                                        
                                        {needsToAcceptTCS && (
                                            <label className="mt-1">
                                                <div className="checkbox">
                                                <Checkbox
                                                    sx={{ color: "#19a49d !important", fontSize: "2rem", padding:0 }}
                                                    color="success"
                                                    checked={acceptedInsuranceTCS}
                                                    onChange={toggleAcceptedInsuranceTCS}
                                                />
                                                </div>
                                                <span>
                                                    I have read and understand the insurance <a href="#_" onClick={openInsurancePolicyWordingModal}><strong>terms & conditions</strong></a>
                                                </span>
                                            </label>
                                        )}
                                    
                                
                                </div>
                            </div>
                        )}

                    {
                        priceObj && itemDetails && (
                            <>
                                <div className="checkout-panel">
                                    <div className="item-main">
                                        <PricingBreakdown 
                                            itemDetails={itemDetails} 
                                            priceIsLoading={priceIsLoading} 
                                            priceObj={priceObj} 
                                            startDate={startDate} 
                                            endDate={endDate} 
                                            networkErrorText={networkErrorText}
                                        />
                                        <Button
                                            disabled={bookingLoading}
                                            color="primary"
                                            onClick={placeBooking}
                                            className={`book-btn ${!acceptedInsuranceTCS && needsToAcceptTCS ? 'opacity-4' : ''}`}>
                                              {bookingLoading && <FontAwesomeIcon icon={faSpinner} spin/>}{" "}
                                              {
                                                bookingLoading ? "Placing Booking..." : "Book Now"
                                              }
                                        </Button>

                                        <div className="policy-documents">
                                            <a
                                                href="#_"
                                                className="general-more-link"
                                                onClick={(e) => openCancellationPolicyModal(e)}
                                            >
                                                <span>{t("item_detailed_cancellation_policy")}</span>
                                                <FontAwesomeIcon icon={faFileCircleCheck} />
                                            </a>
                                            {hasInsurancePolicy && (
                                           <>
                                                <a
                                                    href="#_"
                                                    className="general-more-link"
                                                    onClick={(e) => openInsurancePolicyWordingModal(e)}
                                                >
                                                    <span>Insurance Policy Wording</span>
                                                    <FontAwesomeIcon icon={faFileCircleCheck} />
                                                </a>
                                                
                                                <a
                                                    href="#_"
                                                    className="general-more-link"
                                                    onClick={(e) => openInsuranceIPIDModal(e)}
                                                >
                                                    <span>Insurance IPID</span>
                                                    <FontAwesomeIcon icon={faFileCircleCheck} />
                                                </a>
                                                
                                                <p className="small-text">
                                                    ProtectMy Ltd, Firm Reference No. 1018860, is an Appointed Representative of Innovative Risk Labs Ltd Authorised and Regulated by the Financial Conduct Authority Firm Reference No. 609155
                                                </p>
                                            </>
                                            )}
                                        </div>
                            
                                    </div>
                                </div>
                               
                            </>
                        )
                    }
                  
                    
                </Col>
            </Row>

            </Col>
                <Col sm={1}>
                
                </Col>
            </Row>
                        
            {
                itemDetails && (
                    <BasicModal
                        headerClass={""}
                        modalTitle={"Cancellation Policy"}
                        modalBody={returnCorrectCancellationBody(
                            itemDetails.cancellationPolicy
                        )}
                        modalState={cancellationPolicyModalOpen}
                        toggleModal={() => setCancellationPolicyModalOpen(false)}
                  />
                )
            }


            <BasicModal
                headerClass={""}
                modalTitle={"Insurance Policy Wording"}
                modalBody={<PolicyWording />}
                modalState={insurancePolicyWordingModalOpen}
                toggleModal={() => setInsurancePolicyWordingModalOpen(false)}
            />

            <BasicModal
                headerClass={""}
                modalTitle={"Insurance IPID"}
                modalBody={<IPIDWording />}
                modalState={insuranceIPIDModalOpen}
                toggleModal={() => setInsuranceIPIDModalOpen(false)}
            />
                    
            
            </Container>
    )
}