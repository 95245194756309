import React, { useEffect, useState, useRef } from "react";
import DateRangePicker from "../common/DateRangePicker";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BookingClient, BookingCreateDto, IConfig } from "../../api/rentMyApi";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import PricingBreakdown from "./PricingBreakdown";
export default function DatePickerAndPriceView({
  itemDetails,
  scrollToView,
  setBookingData,
}) {
  const now = new moment();
  const tomorrow = now.clone().add(1, "day").toDate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [datesAreValid, setDatesAreValid] = useState(false);
  const [priceObj, setPriceObj] = useState(null);
  const [priceIsLoading, setPriceIsLoading] = useState(false);
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [networkErrorText, setNetworkErrorText] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const { t } = useTranslation();

  const onChange = (dates) => {
    const [start, end] = dates;
    const fixedStart = start ? new moment(start).startOf("day").toDate() : null;
    setStartDate(fixedStart);
    const fixedEnd = end ? new moment(end).endOf("day").toDate() : null;
    setEndDate(fixedEnd);
  };

  const datesValid = () => {
    if (!startDate || !endDate) return false;
    if (new moment(startDate).isAfter(new moment(endDate))) return false;
    if (new moment(startDate).isSame(new moment(endDate), "day")) return false;
    return true;
  };

  const getBookedDates = async () => {
    const bookingClient = new BookingClient(
      new IConfig("notoken"),
      REACT_APP_API_ENDPOINT
    );
    const response = await bookingClient.upcoming(itemDetails.id);
    const blockedDates = [];

    response.upcomingBookings.forEach((dates) => {
      const start = new moment(dates.startingDate).startOf("day");
      const end = new moment(dates.endingDate).startOf("day");
      while (start.isBefore(end)) {
        blockedDates.push(start.clone().toDate());
        start.add(1, "days");
      }
      blockedDates.push(end.toDate());
    });
    setUpcomingBookings(blockedDates);
  };

  const getMinAndMaxPeriods = (key) => {
    const period = itemDetails?.priceInfo?.periods[0];
    if (!period || !period[key]) return "N/A";
    return period[key];
  };
  const hasInsuranceButIsOptional =
    !itemDetails.requireInsurance && itemDetails.insuranceQuote;

  const checkPrice = async () => {
    setPriceIsLoading(true);

    const bookingClient = new BookingClient(
      new IConfig("notoken"),
      REACT_APP_API_ENDPOINT
    );
    const bookingCreateDto = new BookingCreateDto({
      active: true,
      itemId: itemDetails.id,
      startingDate: startDate,
      endingDate: endDate,
      isHourly: false,
      currency: "GBP",
      vouchers: undefined,
      referralCode: undefined,
      insuranceProductId: itemDetails.requireInsurance
        ? itemDetails.insuranceQuote.productId
        : undefined,
    });

    try {
      const price = await bookingClient.publicPricingCheck(bookingCreateDto);
      setPriceObj(price);
    } catch (err) {
      console.log(err.response);
      setNetworkErrorText(err.response);
    }

    setTimeout(() => {
      setPriceIsLoading(false);
    }, 500);
  };

  const hasMinAndMaxDaysSet = () => {
    const period = itemDetails?.priceInfo?.periods[0];
    return period.minimumBookingDays || period.maximumBookingDays;
  };

  useEffect(() => {
    if (!datesAreValid) {
      setPriceObj(null);
      return;
    }
    checkPrice();
  }, [datesAreValid]);

  useEffect(() => {
    // pass to parent
    setBookingData(priceObj ? { startDate, endDate, priceObj } : null);

    if (priceObj) {
      setTimeout(() => {
        scrollToView();
      }, 300);
    }
  }, [priceObj]);

  useEffect(() => {
    setDatesAreValid(datesValid());
  }, [startDate, endDate]);

  useEffect(() => {
    getBookedDates();
  }, []);

  return (
    <div className="custom-date-picker">
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        minDate={tomorrow}
        excludeDates={upcomingBookings}
        selectsRange
        selectsDisabledDaysInRange={false}
        inline
        dayClassName={(date) => "single-day"}
      />
      {datesAreValid ? (
        <>
          <PricingBreakdown
            itemDetails={itemDetails}
            priceIsLoading={priceIsLoading}
            priceObj={priceObj}
            startDate={startDate}
            endDate={endDate}
            networkErrorText={networkErrorText}
          />
          {hasInsuranceButIsOptional && !priceIsLoading && priceObj && (
            <div className="optional-insurance-prompt">
              <span>Insurance can be added at the checkout</span>
            </div>
          )}
        </>
      ) : (
        <div className="booking-price-row">
          <div className="dates-not-valid-help">
            {hasMinAndMaxDaysSet() && (
              <div className="min-max">
                <div className="min-or-max left">
                  <span className="key">{t("min_days")}</span>
                  <span className="value">
                    {getMinAndMaxPeriods("minimumBookingDays")}
                  </span>
                </div>
                <div className="min-or-max right">
                  <span className="value">
                    {getMinAndMaxPeriods("maximumBookingDays")}
                  </span>
                  <span className="key">{t("max_days")}</span>
                </div>
              </div>
            )}
            <span className="prompt">{t("pick_date_range_for_price")}</span>
          </div>
        </div>
      )}
    </div>
  );
}
