import {
  faCircleCheck,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const IPIDWording = () => {
  return (
    <div className="policy-document">
      <div className="protectmy-logo"></div>

      <div className="ipid-section">
        <div className="ipd-title">
          <div className="icon">
            <img src="/assets/documents/insurance/green1.png" />
          </div>
          <div>
            <h4>What is Insured?</h4>
            <p>
              You must check your policy schedule to see the rental property you
              have selected along with the amount insured.
            </p>
          </div>
        </div>
        <ul className="unstyles-list">
          <li>
            <span
              className="box-bullet"
              style={{
                backgroundImage: `url(/assets/documents/insurance/green2.png)`,
              }}
            ></span>
            The rental property detailed in the policy schedule.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{
                backgroundImage: `url(/assets/documents/insurance/green2.png)`,
              }}
            ></span>
            Damage (loss, theft, or damage) to the rental item(s).
          </li>
        </ul>
      </div>

      <div className="ipid-section">
        <div className="ipd-title">
          <div className="icon">
            <img src="/assets/documents/insurance/red1.png" />
          </div>

          <div>
            <h4>What is Not Insured?</h4>
            <p>
              The following list is not exhaustive. More information can be
              found in your policy documentation.
            </p>
          </div>
        </div>
        <ul className="unstyles-list">
          <li>
            <span
              className="box-bullet"
              style={{
                backgroundImage: `url(/assets/documents/insurance/red2.png)`,
              }}
            ></span>
            Wear & tear, electrical or mechanical breakdown.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{
                backgroundImage: `url(/assets/documents/insurance/red2.png)`,
              }}
            ></span>
            Inherent defects in the rental item.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{
                backgroundImage: `url(/assets/documents/insurance/red2.png)`,
              }}
            ></span>
            Damage or injury to the owner or renter or any third party or
            third-party property resulting from the rental item.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{
                backgroundImage: `url(/assets/documents/insurance/red2.png)`,
              }}
            ></span>
            Loss of any data held or stored an any electronic device rental
            item.
          </li>
        </ul>
      </div>

      <div className="ipid-section">
        <div className="ipd-title">
          <div className="icon">
            <img src="/assets/documents/insurance/orange1.png" />
          </div>

          <div>
            <h4>Are there any restrictions on cover?</h4>
          </div>
        </div>
        <ul className="unstyles-list">
          <li>
            <span
              className="box-bullet"
              style={{
                backgroundImage: `url(/assets/documents/insurance/orange2.png)`,
              }}
            ></span>
            There are no restrictions on cover.
          </li>
        </ul>
      </div>

      <div className="ipid-section">
        <div className="ipd-title">
          <div className="icon">
            <img src="/assets/documents/insurance/blue1.png" />
          </div>

          <div>
            <h4>Where am I covered?</h4>
          </div>
        </div>
        <ul className="unstyles-list">
          <li>
            <span
              className="box-bullet"
              style={{
                backgroundImage: `url(/assets/documents/insurance/blue2.png)`,
              }}
            ></span>
            Anywhere in the United Kingdom, the Channel Islands, and the Isle of
            Man.
          </li>
        </ul>
      </div>

      <div className="ipid-section">
        <div className="ipd-title">
          <div className="icon">
            <img src="/assets/documents/insurance/lgreen1.png" />
          </div>

          <div>
            <h4>What are my obligations?</h4>
          </div>
        </div>
        <ul className="unstyles-list">
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#92cf51" }}
            ></span>
            The owner and the renter must take all reasonable precautions to
            prevent Damage to the rental property.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#92cf51" }}
            ></span>
            Report any theft of the rental property to the police and obtain a
            crime reference number.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#92cf51" }}
            ></span>
            The Owner must take and retain photos or videos of the hire item(s),
            immediately before the rental period commences, and no more than 24
            hours prior to the rental period, to prove that the item(s) were in
            The Owner's possession at the time and the item(s) current physical
            condition.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#92cf51" }}
            ></span>
            The Owner must take and retain photos or videos of the hire item(s)
            immediately after the rental period has ceased, and no more than 24
            hours after the rental period, to prove that the item(s) is in The
            Owner's possession at the time and the item(s) current physical
            condition, however this will not apply in the event that the item(s)
            have been stolen or lost during the rental period.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#92cf51" }}
            ></span>
            The Renter must take and retain photos or videos of the hire
            item(s), immediately upon receipt of the hire item(s), and no more
            than 24 hours after receipt of the item(s), to prove that the
            item(s) were in The Renter's possession at the time and the item(s)
            current physical condition.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#92cf51" }}
            ></span>
            The Renter must take and retain photos or videos of the hire
            item(s), immediately upon returning the hire item(s) to The Owner to
            prove the items(s) current physical condition, however this will not
            apply in the event that the item(s) has been stolen or lost.
          </li>
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#92cf51" }}
            ></span>
            In the event of Damage, the renter must notify the owner within 48
            hours of the end of the rental period of its damage.
          </li>
        </ul>
      </div>

      <div className="ipid-section">
        <div className="ipd-title">
          <div className="icon">
            <img src="/assets/documents/insurance/yellow1.png" />
          </div>

          <div>
            <h4>When and how do I pay?</h4>
          </div>
        </div>
        <ul className="unstyles-list">
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#ffce2b" }}
            ></span>
            Payment request will be all at once during the booking and
            confirmation process of the rental transaction - this can be done by
            credit/debit card.
          </li>
        </ul>
      </div>

      <div className="ipid-section">
        <div className="ipd-title">
          <div className="icon">
            <img src="/assets/documents/insurance/lblue1.png" />
          </div>

          <div>
            <h4>When does cover start and end?</h4>
          </div>
        </div>
        <ul className="unstyles-list">
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#00b0f1" }}
            ></span>{" "}
            Cover starts once you have paid the premium. It will last for the
            duration of the rental period specified in the schedule, unless it
            is cancelled by you before the rental period commences.
          </li>
        </ul>
      </div>

      <div className="ipid-section">
        <div className="ipd-title">
          <div className="icon">
            <img src="/assets/documents/insurance/grey1.png" />
          </div>

          <div>
            <h4>How do I cancel the contract?</h4>
          </div>
        </div>
        <ul className="unstyles-list">
          <li>
            <span
              className="box-bullet"
              style={{ backgroundColor: "#767070" }}
            ></span>{" "}
            The rental insurance can be cancelled before the rental period has
            begun and a full refund of the premium paid will be made to the
            renter.
          </li>
        </ul>
      </div>
      <div className="ipid-section mt-4">
        <p>
          Bspoke Commercial Ltd on behalf of Watford Insurance Company Europe
          Ltd
          <br />
          Bspoke Commercial Ltd, Brookfield Court, Selby Road, Leeds, LS25 1NB
          <br />
          Bspoke Commercial Ltd are authorised and regulated by the Financial
          Conduct Authority. Firm Reference No. 709456
          <br />
          Watford Insurance Company Europe Limited is authorised and regulated
          by the Gibraltar Financial Services Commission under incorporation
          number 112869. This can be checked by visiting the Gibraltar FSC
          website at https://www.fsc.gi/.
        </p>
      </div>

      <div className="ipid-section mt-4">
        <p>
          ProtectMy Ltd, Firm Reference No. 1018860, is an Appointed
          Representative of Innovative Risk Labs Ltd Authorised and Regulated by
          the Financial Conduct Authority Firm Reference No. 609155
        </p>
      </div>
    </div>
  );
};

export default IPIDWording;
