import { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import { BookingClient, BookingCreateDto, BookingPricingDto, CurrencyIsoCode, IConfig } from '../../api/rentMyApi';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function InsuranceToggle({itemDetails, insuranceChosen, handleInsuranceToggle }) {

    const location = useLocation();
    const { REACT_APP_API_ENDPOINT: endpoint } = process.env;
    const params = new URLSearchParams(location.search);

    const [priceObj, setPriceObj] = useState<BookingPricingDto>();
    const [priceIsLoading, setPriceIsLoading] = useState(false);
    const startDate = params.get("start-date") || null;
    const endDate = params.get("end-date") || null;
    const [networkErrorText, setNetworkErrorText] = useState<string>("");

    const getQuoteWithInsurance = async () => {

        const bookingClient = new BookingClient(
            new IConfig("notoken"),
            endpoint
        );

        setPriceIsLoading(true);

        const bookingCreateDto = new BookingCreateDto({
            active: true,
            itemId: itemDetails.id,
            startingDate: moment(startDate).toDate(),
            endingDate: moment(endDate).toDate(),
            isHourly: false,
            currency: CurrencyIsoCode.GBP,
            vouchers: undefined,
            referralCode: undefined,
            insuranceProductId: itemDetails?.insuranceQuote?.productId ?? undefined
        });

        try {
            const price = await bookingClient.publicPricingCheck(bookingCreateDto);
            setPriceObj(price);
          } catch (err: any) {
             setNetworkErrorText(err.response);
          }
      
          setTimeout(() => {
            setPriceIsLoading(false);
          }, 500);
    }

    useEffect(() => {
        if(!itemDetails) return () => {}
        getQuoteWithInsurance()
    }, [itemDetails])

    return networkErrorText.length > 0 ? <label>{networkErrorText}</label> : (
        <>
         {
            !itemDetails?.requireInsurance && (
                <label>
                    {
                        priceIsLoading ? (
                            <span className="opacity-7">
                                <FontAwesomeIcon icon={faSpinner} spin/> Fetching a price..
                            </span>
                        ) : priceObj?.insuranceQuote && priceObj.insuranceQuote.total ? (
                            <>
                                <div className="checkbox">
                                    <Checkbox
                                        sx={{ color: "#19a49d !important", fontSize: "2rem", padding:0 }}
                                        color="success"
                                        checked={insuranceChosen}
                                        onChange={handleInsuranceToggle}
                                        />
                                </div>
                                <span>
                                    Add{' '}<strong>£{priceObj.insuranceQuote.total.toFixed(2)}</strong>{' '}Insurance
                                </span>
                            </>
                        ) : (
                            <span>Sorry, we couldn't find a price for insurance</span>
                        )
                    }
                </label>
            )}
        </>
                                            
    )

}