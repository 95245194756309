import React from "react";

const PolicyWording = () => {
  return (
    <div className="policy-document">
      <div className="policy-document-header-banner">
        <div className="protectmy-logo"></div>
        <p>JGC-SEI-PM-V1.2-12/24</p>
      </div>
      <p>
        ProtectMy Limited is an Appointed Representative of Innovative Risk Labs
        Limited (IRL). Innovative Risk Labs Limited is authorised and regulated
        by the Financial Conduct Authority (FCA) under firm reference
        number:609155. ProtectMy firm reference number is 1018860, our permitted
        business is arranging general insurance contracts. Details can be
        checked on the FCA register by visiting https://register.fca.org.uk/s/
        or by contacting the FCA on 0800 111 6768.
      </p>

      <h4>Your Policy</h4>
      <p>
        This policy is underwritten by Bspoke Underwriting Ltd on behalf of
        Watford Insurance Company Europe Ltd. Bspoke Underwriting Ltd are
        authorised and regulated by the Financial Conduct Authority. Firm
        Reference No. 310101.
      </p>
      <p>
        Watford Insurance Company Europe Limited is authorised and regulated by
        the Gibraltar Financial Services Commission under incorporation number
        112869. This can be checked by visiting the Gibraltar FSC website at
        https://www.fsc.gi/.
      </p>
      <p>
        As an insurance company authorised in Gibraltar, Watford Insurance
        Company Europe Limited is permitted under the Financial Services and
        Markets Act 2000 (Gibraltar) Order 2001 to conduct business in the
        United Kingdom under FCA reference 714197. You can check this by
        visiting the Financial Services Register on the FCA website at
        https://register.fca.org.uk.
      </p>
      <p>
        ProtectMy Limited. Registered Office: C/O Mjb Avanti, Office 12, Epsilon
        House, West Road, Ipswich, Suffolk, England, IP3 9FJ, is an Appointed
        Representative of Innovative Risk Labs Limited (IRL). Innovative Risk
        Labs Limited is authorised and regulated by the Financial Conduct
        Authority (FCA) under firm reference number: 609155.
      </p>
      <p>
        ProtectMy firm reference number is 1018860, our permitted business is
        arranging general insurance contracts.
      </p>

      <h4>Who is insured?</h4>
      <p>
        This insurance is a joint policy between the The Renter and The Owner as
        detailed in The Schedule, for property owned and rented out by The Owner
        to The Renter during the Rental Period.
      </p>

      <h4>Definitions</h4>
      <p>
        Each time we use one of the words listed below, it will have the same
        meaning wherever it appears in Your policy unless an alternative
        Definition is stated to apply. A defined word will be in bold and start
        with a capital letter each time it appears in the policy.
      </p>
      <p>
        <strong>The Policyholders</strong> The Owner and The Renter as named in
        The Schedule
      </p>
      <p>
        <strong>Damage</strong> Loss, theft or damage.
      </p>
      <p>
        <strong>Rental Property</strong> The listed item(s) and any accessories
        as detailed in The Schedule.
      </p>
      <p>
        <strong>The Renter</strong> The person named as The Renter in The
        Schedule.
      </p>
      <p>
        <strong>The Owner</strong> The person named as The Owner in The
        Schedule.
      </p>
      <p>
        <strong>Rental Period</strong> The period of time covered by this policy
        as detailed in The Schedule.
      </p>
      <p>
        <strong>The Schedule</strong> The document that specifies details of The
        Policyholders, Rental Property & Rental Period.
      </p>
      <p>
        <strong>Replacement Value</strong> The replacement value of the Rental
        Property as detailed in The Schedule
      </p>
      <p>
        <strong>You/Your</strong> The Policyholders as named in The Schedule
      </p>

      <h4>What is covered?</h4>
      <p>
        In return for The Renter having paid the insurance premium for the
        Rental Period, The Renter will not be responsible to reimburse The Owner
        for the cost of replacement or repair of the Rental Property as a result
        of Damage caused during the Rental Period and we will indemnify The
        Owner whilst renting out their property to The Renter, in respect of
        Damage to the Rental Property as stated in the Schedule, caused during
        the Rental Period for the cost of replacement or repair of the Rental
        Property, to a condition as good as, but not better or more extensive
        than its condition immediately prior to the Damage.
      </p>

      <h4>What is not covered?</h4>
      <p>
        A) Damage which cannot be proven to have occurred during the Rental
        Period.
      </p>
      <p>
        B) Wear & tear (natural and predictable damage which happens over time
        or due to normal use or ageing) or minor damage that does not affect the
        functionality or appearance of the Rental Property during the Rental
        Period.
      </p>
      <p>
        C) Damage caused by previous incidents of damage, alteration, cleaning,
        repair or similar process, misuse, faulty workmanship, or the use of
        faulty materials.
      </p>
      <p>D) Damage caused by inherent defect(s) in the Rental Property.</p>
      <p>
        E) Rental Property that has been handed over to any person outside of
        this Policy.
      </p>
      <p>
        F) Damage to Rental Property that occurs outside the United Kingdom, the
        Channel Islands, and the Isle of Man.
      </p>
      <p>
        G) Any loss or Damage for which a claim has already been made under
        another insurance policy which covers you for the same loss or Damage.
      </p>
      <p>
        H) damage or injury resulting from the Rental Property to The Renter or
        The Owner or to any third party or third-party property.
      </p>
      <p>
        I) Damage caused by The Owner intentionally or deliberately to commit a
        fraudulent act, embezzlement, or similar property crimes, or any other
        illegal activities.
      </p>
      <p>
        J) Rental Property that The Owner does not own or is legally responsible
        for.
      </p>
      <p>
        K) Damage to the Rental Property caused by mechanical and or electrical
        fault or breakdown.
      </p>
      <p>L) Loss of data held or stored in any electronic device.</p>
      <p>
        M) Notwithstanding any other provision herein, this insurance does not
        cover any actual or alleged loss, damage, liability, injury, sickness,
        cost or expense, regardless of any other cause contributing concurrently
        or in any sequence, in any way caused by or resulting directly or
        indirectly;
      </p>
      <p>I) Infectious or contagious disease;</p>
      <p>II) any fear or threat of (I) above; or</p>
      <p>
        III) any action taken to minimise or prevent the impact of (I) above.
      </p>
      <p>
        Infectious or contagious disease means any disease capable of being
        transmitted from an infected person, animal or species to another
        person, animal, or species by any means.
      </p>

      <h4>Conditions of Cover</h4>
      <p>The following conditions apply to The Owner;</p>
      <p>
        A) The Owner must take all reasonable precautions to prevent Damage to
        the Rental Property.
      </p>
      <p>
        B) The Owner must make sure that the Rental Property is fit for any
        purpose for which such items would normally be used.
      </p>
      <p>
        C) In the event of theft, or non-return of the Rental Property, The
        Owner must report the incident to the police as soon as reasonably
        possible and obtain a crime reference number from them.
      </p>
      <p>
        D) The Owner must take and retain photos or videos of the hire item(s),
        immediately before the rental period commences, and no more than 24
        hours prior to the rental period, to prove that the item(s) were in The
        Owner's possession at the time and the item(s) current physical
        condition.
      </p>
      <p>
        E) The Owner must take and retain photos or videos of the hire item(s)
        immediately after the rental period has ceased, and no more than 24
        hours after the rental period, to prove that the item(s) is in The
        Owner's possession at the time and the item(s) current physical
        condition, however this will not apply in the event that the item(s)
        have been stolen or lost during the rental period.
      </p>
      <p>F) In the event of a claim under this policy The Owner must;</p>
      <p>
        i) report the claim on the RentMy.com claim registration page as soon as
        reasonably possible or within 7 working days of the end of the Rental
        Period,
      </p>
      <p>
        ii) provide the appropriate documentation and photo images or videos to
        support the claim,
      </p>
      <p>
        iii) provide evidence of the value of the Rental Property when
        purchased,
      </p>
      <p>
        iv) where available provide serial numbers on the Rental Property
        item(s).
      </p>
      <p>
        G) In the event of a claim for repairable damage, The Owner must provide
        a receipt of repair or proof of estimated repair costs and upload as
        part of the claim submission for the value of The Owner's claim to be
        validated.
      </p>
      <p>
        H) In the event of a claim for irreparable damage, loss or theft, The
        Owner must provide an online image including its price for the
        replacement of the same or similar item(s) and upload as part of the
        claim submission for the value of The Owner's claim to be validated.
      </p>
      <p>The following conditions apply to The Renter;</p>
      <p>
        I) The Renter must take all reasonable precautions to prevent Damage to
        the Rental Property.
      </p>
      <p>
        J) The Renter must inform The Owner as soon as possible, and in any
        event after 48 hours of the end date of the Rental Period of any
        incident where Damage has occurred.
      </p>
      <p>
        K) The Renter must take and retain photos or videos of the hire item(s),
        immediately upon receipt of the hire item(s), and no more than 24 hours
        after receipt of the item(s), to prove that the item(s) were in The
        Renter's possession at the time and the item(s) current physical
        condition.
      </p>
      <p>
        L) The Renter must take and retain photos or videos of the hire item(s),
        immediately upon returning the hire item(s) to The Owner to prove the
        items(s) current physical condition, however this will not apply in the
        event that the item(s) has been stolen or lost.
      </p>
      <p>
        M) The Renter must report any theft of the item(s) by a third party to
        the police as soon as reasonably possible and obtain a crime reference
        number and provide these details to the Owner.
      </p>

      <h4>Cancellation</h4>
      <p>
        The Renter may cancel this policy at any time before the Rental Period
        has commenced and the full premium paid will be refunded back to The
        Renter.
      </p>

      <h4>Applicable Law</h4>
      <p>The law of England and Wales will apply to this contract.</p>

      <h4>How to make a claim</h4>
      <p>
        If The Owner needs to make a claim, they can do this online at
        RentMy.com under My Account and follow the instructions provided.
      </p>
      <p>
        In the event it is not possible to register a claim online, please
        contact ProtectMy Ltd:
      </p>
      <p>Email: claims@protectmy.co.uk</p>
      <p>Telephone: 01206 692497</p>

      <h4>When making a claim</h4>
      <p>When making a claim for Damage, we may ask for the following:</p>
      <p>
        - Pre and post rental digital photo images of what has been damaged or
        lost in respect of the Rental Property.
      </p>
      <p>
        - Receipts, quotes or invoices for the repair or replacement of the
        Rental Property.
      </p>
      <p>
        Photos and documents (word, pdf) can be uploaded when telling us about a
        claim.
      </p>

      <h4>Contacting Us & Complaints</h4>
      <p>
        If You need to contact us in relation to the ProtectMy Rental Insurance,
        please email our Insurance Providers – ProtectMy Ltd at
        support@protectmy.co.uk or by phone on 01206 692497.
      </p>
      <p>
        If You are unhappy with any aspect of the handling of Your insurance, we
        would encourage You register Your complaint to ProtectMy Ltd and we
        will;
      </p>
      <p>- Acknowledge Your complaint promptly,</p>
      <p>
        - Assign a dedicated complaint expert who will review Your complaint,
      </p>
      <p>- Carry out a thorough and impartial investigation,</p>
      <p>- Keep You updated of the progress,</p>
      <p>- Do everything we can to resolve things as quickly as possible,</p>
      <p>
        - Provide a response within eight weeks of receiving Your complaint,
        this will inform You of the results of our investigation or explain why
        this isn't possible.
      </p>
      <p>
        Where we have been unable to resolve Your concerns or been unable to
        resolve Your complaint within eight weeks, You may be able to ask the
        Financial Ombudsman Service to carry out an independent review. Whilst
        we are bound by their decision, You are not.
      </p>
      <p>Contacting them will not affect Your legal rights.</p>
      <p>
        You can contact the Financial Ombudsman Service by telephone on 0800 023
        4567. You can also visit their website at www.financial-ombudsman.org.uk
        where You will find further information.
      </p>

      <h4>Financial Services Compensation Scheme (FSCS)</h4>
      <p>Watford Insurance Company Europe Ltd is covered by the FSCS.</p>
      <p>
        You may be entitled to compensation from the scheme in the unlikely
        event we cannot meet our obligations to you. This depends on the type of
        insurance, size of the business and the circumstances of the claim.
      </p>
      <p>
        Further information about the compensation scheme arrangements is
        available from FSCS (www.fscs.org.uk)
      </p>
    </div>
  );
};

export default PolicyWording;
