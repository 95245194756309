import { useEffect, useState } from "react";
import {
  IConfig,
  ReferralClient,
  ReferralSimpleDto,
} from "../../../api/rentMyApi";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useEnv } from "../../../context/env.context";
import { useUserContext } from "../../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

interface ReferralsProps {
  handleReferralsClick: () => void;
}
export const Referrals = ({ handleReferralsClick }: ReferralsProps) => {
  const [wrapText, setWrapText] = useState(false);
  const [referralData, setReferralData] = useState(new ReferralSimpleDto());
  const [isLoading, setIsLoading] = useState(true);
  const [opacity, setOpacity] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const { user } = useUserContext();
  const [tooltipText, setTooltipText] = useState("Copy");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    const fetchReferralData = async () => {
      const token = await getAccessTokenSilently();
      const referralClient = new ReferralClient(
        new IConfig(token),
        process.env.REACT_APP_API_ENDPOINT
      );
      try {
        const response = await referralClient.referral(user.id);
        setReferralData(response);
        console.log("REFERRAL");
        console.log(response);
      } catch (e) {
        console.error(e);
      }

      setIsLoading(false);
      setOpacity(1);
    };

    fetchReferralData();
  }, [getAccessTokenSilently]);

  const handleShareClick = () => {
    const subject = encodeURIComponent(
      `${user.firstName} has something awesome to share with you`
    );
    const rentMyUrl = "https://rentmy.com"; // Replace with your actual URL
    const body = encodeURIComponent(`Hey there,

    ${user.firstName} thinks you should checkout RentMy.com - an app where you can share stuff with people local to you!
    It's a bit like Airbnb, but for your belongings: think power tools, cameras, kayaks, tents, trailers - the list is endless. 

    Whether sharing your stuff and making money from it, or renting from other people to try something new or access something you need, RentMy is the place to be!
    And even better, because ${user.firstName} has referred you, you'll get £10 to use on your first rental - sign up now at ${rentMyUrl} or download the app from your preferred app store!
    And enter the referral code when booking your first rental for £10 off!

    Referral code: ${user.referral?.code}

    The small print:
    - It's free to sign up
    - Add the voucher when you book your first rental over £60 and get £10 off!
    - Your referrer will also get a little thank you for being an awesome friend

    Happy renting!
    `);

    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  return (
    <Card>
      <CardBody>
        <div className="text-align-center mb-4">
          <h2>Share the Savings</h2>
        </div>
        <div className="icon-row mb-4">
          <img
            className="referral-icon"
            src="/assets/svgs/referrals-icons.svg"
            alt="referrals icons"
          />
        </div>
        <p className="referral-description mb-3">
          Share the joy of renting.
          <br />
          Give a £10 voucher by sharing the code below.
        </p>
        <div className="text-align-center mb-3">
          <p>Unique referral code</p>
          <h2 className="user-code">
            {user.referral?.code || "No code available"}
            {user.referral?.code && (
              <div
                className="copy-button"
                onMouseEnter={() => {
                  setIsTooltipVisible(true);
                  setTooltipText("Copy");
                }}
                onMouseLeave={() => setIsTooltipVisible(false)}
                onClick={() => {
                  if (typeof user.referral.code === "string") {
                    navigator.clipboard.writeText(user.referral.code);
                  }
                  setTooltipText("Copied");
                }}
              >
                <FontAwesomeIcon icon={faCopy} color="grey" />
                <span
                  className={`tooltip ${
                    isTooltipVisible ? "visible" : "hidden"
                  }`}
                >
                  {tooltipText}
                </span>
              </div>
            )}
          </h2>
        </div>
        <Row>
          <Col>
            <Button className="btn-primary" block onClick={handleShareClick}>
              Share
            </Button>
          </Col>
          <Col>
            <Button
              className="btn-outline-primary"
              block
              onClick={handleReferralsClick}
            >
              View Referrals
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
