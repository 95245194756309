import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Container, Col, Row } from "reactstrap";
import parse from 'html-react-parser';
import timeDisplay from "../../utils/timeDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import { LoadingFullSide } from "../../components/common/LoadingFullScreen";
import MetaTags from "../../components/SEO/MetaTags";
export function SingleBlogPost() {

    const blogURL = process.env.REACT_APP_BLOG_URL;
    const blogAPIKey = process.env.REACT_APP_BLOG_API_KEY;
    const {postid} = useParams()
    const [loading, setLoading]: Array<any> = useState(true);
    const [blogPost, setBlogPost]: Array<any> = useState(null);
    
    const getContent = async() => {
        setLoading(true)
        try {
            const res = await axios.get(`${blogURL}/posts/${postid}/?key=${blogAPIKey}`);
            setBlogPost(res.data)
        } catch(error) {
            console.error("Error fetching content: ", error);
        }
        setLoading(false)
    }
    useEffect(() => {
        getContent();
    }, []);
    
    return (
        <>

            {!blogPost && (
                <MetaTags
                    title="Blog Post | RentMy Blog"
                    description="Discover insights and tips about equipment rental on the RentMy Blog."
                    keywords="equipment rental, rental blog, RentMy blog, rental tips"
                    canonicalUrl="/blog"
                />
            )}
            {loading ? <LoadingFullSide/> : (
                <>
                {blogPost && (
                    <MetaTags
                        title={`${blogPost.title} | RentMy Blog`}
                        description={blogPost.excerpt || `Read about ${blogPost.title} on RentMy's blog for equipment rental tips, guides, and industry insights.`}
                        keywords={`${blogPost.tags?.join(', ')}, rental blog, equipment rental tips, RentMy blog`}
                        canonicalUrl={`/blog/${postid}`}
                    />
                )}
                <div className="height-wrapper">
                    <Container className="mt-4 static-pages-wrapper blog">
                        <Row>
                            <Col xs={{
                                offset: 0,
                                size: 12
                            }}
                            sm={{
                                offset: 1,
                                size: 10
                            }}
                            >
                            {blogPost && (
                            <article className="single-blog-page">
                                <Link to="/blog" className="back-link">
                                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                                Back to all articles</Link>
                                <h1 className="single-blog-title">{blogPost.title}</h1>
                                <span className="publish-date">Published {timeDisplay(blogPost.published)} by {blogPost.author.displayName}</span>
                                <div className="blog-post-body">
                                {parse(blogPost.content)}
                                </div>        

                                <Link to="/blog" className="back-link pb-3 block">
                                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                                Back to all articles</Link>

                                <div className="single-entry-publisher p-0 pt-3">
                                <div className="left">
                                    <div className="publisher-image" style={{backgroundImage: `url(${blogPost.author.image.url})`}}></div>
                                </div>
                                <div className="right">
                                    <span className="published-by-text">Published by </span>
                                    <span className="published-name-text">{blogPost.author.displayName}</span>
                                    <span className="published-by-text"> on {timeDisplay(blogPost.published)}</span>
                                </div>
                                </div>
                            </article>
                            )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
                </>
            )}
        </>
    )
}