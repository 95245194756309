// HowItWorks.tsx
// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import { Container, Col, Row } from "reactstrap";
import Footer from "../components/layout/Footer";
import MetaTags from "../components/SEO/MetaTags";
import { useSwipeable } from "react-swipeable";

interface Media {
  system: { name: string };
  fields: { umbracoFile: { src: string } };
}

interface Content {
  system: { name: string };
  fields: {
    button1_1: string;
    button1_2: string;
    header2_1: string;
    body2_1: string;
    media2_1: Media[];
    header2_2: string;
    body2_2: string;
    media2_2: Media[];
    header2_3: string;
    body2_3: string;
    media2_3: Media[];
    header3_1: string;
    body3_1: string;
    media3_1: Media[];
    header3_2: string;
    body3_2: string;
    media3_2: Media[];
    header3_3: string;
    body3_3: string;
    media3_3: Media[];
  };
}

export function HowItWorks() {
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState(false);
  // By default, show the sharers section (section #3)
  const [currentSection, setCurrentSection] = useState(3);
  // Track which subsection is being displayed (1, 2, or 3)
  const [currentSubsection, setCurrentSubsection] = useState(1);
  const autoSlideRef = useRef<NodeJS.Timeout | null>(null);

  const { REACT_APP_CMS_URL, REACT_APP_CMS_HOW_IT_WORKS } = process.env;

  const getContent = async () => {
    setLoading(true);
    try {
      const res = await axios.get(REACT_APP_CMS_URL + REACT_APP_CMS_HOW_IT_WORKS);
      console.log("Content loaded:", res.data);
      setContent(res.data as Content);
    } catch (error) {
      console.error("Error fetching content: ", error);
    }
    setLoading(false);
  };

  const getImage = (media: Media) => {
    // Check if media has the required structure
    if (!media || !media.fields || !media.fields.umbracoFile || !media.fields.umbracoFile.src) {
      console.error('Invalid media structure:', media);
      return '';
    }
    return REACT_APP_CMS_URL + media.fields.umbracoFile.src;
  };
  
  useEffect(() => {
    getContent();
  }, []);
  
  // Reset subsection when changing main section
  useEffect(() => {
    setCurrentSubsection(1);
    
    // Clear any existing auto-slide interval
    if (autoSlideRef.current) {
      clearInterval(autoSlideRef.current);
    }
  }, [currentSection]);
  
  // Auto-slide functionality for content on medium-to-large screens
  useEffect(() => {
    // Only auto-slide on medium to large screens
    if (window.innerWidth >= 768) {
      autoSlideRef.current = setInterval(() => {
        setCurrentSubsection((prev) => {
          if (prev < 3) return prev + 1;
          return 1; // Loop back to the first subsection
        });
      }, 5000); // Change every 5 seconds
      
      return () => {
        if (autoSlideRef.current) {
          clearInterval(autoSlideRef.current);
        }
      };
    }
  }, [currentSection]);
  
  // Swipe handlers for mobile
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      console.log("Swiped left");
      if (currentSubsection < 3) {
        setCurrentSubsection(currentSubsection + 1);
      }
    },
    onSwipedRight: () => {
      console.log("Swiped right");
      if (currentSubsection > 1) {
        setCurrentSubsection(currentSubsection - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Enable for testing on desktop with mouse
    trackTouch: true
  });

  const ImageSlider = ({ media }: { media: Media[] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    // Auto-slide every 1.8s on desktop
    useEffect(() => {
      if (window.innerWidth > 768 && media.length > 1) {
        intervalRef.current = setInterval(() => {
          setCurrentIndex((prev) => (prev + 1) % media.length);
        }, 1800);
        return () => {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        };
      }
    }, [media]);

    return (
      <div className="image-slider-wrapper position-relative">
        <div className="image-slider">
          {media.map((m, idx) => (
            <div
              key={idx}
              className={`image-slide ${idx === currentIndex ? "active" : ""}`}
            >
              <img
                src={getImage(m)}
                alt={m.system?.name || 'Image'}
                className="img-fluid slider-image"
                loading="lazy"
              />
            </div>
          ))}
        </div>

        {media.length > 1 && (
          <div className="slider-dots">
            {media.map((_, idx) => (
              <span
                key={idx}
                className={`dot ${idx === currentIndex ? "active" : ""}`}
                onClick={() => setCurrentIndex(idx)}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderMedia = (media: Media[] | undefined) => {
    if (!media || media.length === 0) {
      console.log('No media to render');
      return null;
    }
    
    if (media.length > 1) {
      return <ImageSlider media={media} />;
    }
    
    // Add additional check to ensure media[0] exists and has the required properties
    if (!media[0] || !media[0].fields || !media[0].fields.umbracoFile) {
      console.error('Invalid media structure:', media[0]);
      return null;
    }
    
    const imageUrl = getImage(media[0]);
    
    return (
      <img
        alt={media[0].system?.name || 'Image'}
        src={imageUrl}
        className="img-fluid slider-image"
        onError={(e) => {
          console.error('Image failed to load:', imageUrl);
          e.currentTarget.style.display = 'none';
        }}
      />
    );
  };

  if (loading || !content) {
    return (
      <div className="spinner-container">
        <SmallLoadingSpinner />
      </div>
    );
  }

  const getCurrentContent = () => {
    const section = currentSection;
    const subsection = currentSubsection;
    
    const headerKey = `header${section}_${subsection}`;
    const bodyKey = `body${section}_${subsection}`;
    const mediaKey = `media${section}_${subsection}`;
    
    return {
      header: content.fields[headerKey] || '',
      body: content.fields[bodyKey] || '',
      media: content.fields[mediaKey] || []
    };
  };
  const currentContent = getCurrentContent();

  return (
    <Container className="mt-2 static-pages-wrapper">
      <MetaTags
        title="How It Works | RentMy - Peer-to-Peer Equipment Rental Platform"
        description="Learn how RentMy works for both equipment renters and sharers. Our peer-to-peer platform makes renting and sharing equipment simple, secure, and profitable."
        keywords="equipment rental, how it works, peer-to-peer rental, rent equipment, share equipment, RentMy platform"
        canonicalUrl="/how-it-works"
      />

      {/* Top Row with two buttons */}
      <Row className="section">
        <Col xs={12} className="d-flex flex-row justify-content-center align-items-center button-row">  
          {[
            { section: 2, label: content.fields.button1_1 },
            { section: 3, label: content.fields.button1_2 },
          ].map((btn, index) => (
            <button
              key={index}
              className={`how-it-works-buttons ${
                currentSection === btn.section ? "active-btn" : ""
              }`}
              onClick={() => setCurrentSection(btn.section)}
            >
              {btn.label}
            </button>
          ))}
        </Col>
      </Row>

      {/* Dedicated swipeable div that wraps the content */}
      <div 
        {...swipeHandlers} 
        className="swipeable-content"
      >
        <Row className="section howitworks-section">
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column justify-content-start align-items-start text-left"
          >
            <h1 className="page-main-heading">
              How It Works: {currentSection === 2 ? "Renting" : "Sharing"} Equipment
            </h1>
            <h2>{currentContent.header}</h2>
            <div>{parse(currentContent.body)}</div>
            
            {/* Pagination dots */}
            <div className="d-flex justify-content-center w-100 mt-4">
              <div className="pagination-dots">
                {[1, 2, 3].map(num => (
                  <span 
                    key={num}
                    className={`pagination-dot ${currentSubsection === num ? 'active' : ''}`}
                    onClick={() => setCurrentSubsection(num)}
                  />
                ))}
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
            {renderMedia(currentContent.media)}
          </Col>
        </Row>
      </div>

      <Footer />
    </Container>
  );
}